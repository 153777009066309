import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { finalize } from 'rxjs/operators';
import { ApiResponse, Metric, MetricCategory, ValueDefinition, ValueDefinitionGroup } from '../../../../models';

import { MetricStructureSelectable } from '../../../models';
import { MetricStructureStateService } from '../../../services/metric-structure-state.service';
import { CurtainStateService } from '../../../../services/common';
import { MetricApiService } from '../../../../services/types';

@Component({
  selector: 'lib-metric-structure-group-form',
  templateUrl: './metric-structure-group-form.component.html',
  styleUrls: ['./metric-structure-group-form.component.scss'],
})
export class MetricStructureGroupFormComponent implements OnChanges {
  @Input({ required: true }) valueDefinitionGroup!: ValueDefinitionGroup;
  @Input({ required: true }) index!: number;
  @Input({ required: true }) metric!: Metric;
  @Input() canMoveUp: boolean = false;
  @Input() canMoveDown: boolean = false;
  @Input() selectedItemId: string = '';
  @Input() indexOffSet: number = 0;

  @ViewChild('group') group?: ElementRef<HTMLDivElement>;

  readonly eMetricCategory: typeof MetricCategory = MetricCategory;

  constructor(
    private metricsService: MetricApiService,
    private metricStructureService: MetricStructureStateService,
    private curtainStateService: CurtainStateService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedItemId === this.valueDefinitionGroup.id && Boolean(changes.metric)) {
      setTimeout(() => {
        this.group?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 0);
    }
  }

  private setSelectedItem(item?: MetricStructureSelectable): void {
    this.metricStructureService.updateSelectedItem(item);
  }

  public drop(event: CdkDragDrop<unknown> | CustomEvent): void {
    if (!('container' in event) && 'detail' in event) {
      // For unit test only. We can't send any properties directly to custom events, it needs to go through detail.
      event = (event as unknown as CustomEvent).detail as CdkDragDrop<unknown>;
    }

    if (event.previousContainer === event.container) {
      this.moveField(event.container.data as ValueDefinition[], event.previousIndex, event.currentIndex);
      this.setSelectedItem((event.container.data as ValueDefinition[])[event.currentIndex]);
    } else {
      // cdkdragdrop cannot find the correct index when an element is disabled/hidden in the previousContainer
      let previousIndex = event.previousIndex;
      const vdId = event.item.data?.id;
      if (vdId) {
        previousIndex = (event.previousContainer.data as ValueDefinition[]).findIndex((vd) => vd.id === vdId);
      }
      this.metricStructureService.dropElement(
        event.previousContainer.data as ValueDefinition[],
        event.container.data as ValueDefinition[],
        previousIndex,
        event.currentIndex,
      );
    }
  }

  private moveField(valueDefinitions: ValueDefinition[], fromIndex: number, toIndex: number): void {
    this.updateFieldStatus(true);
    const valueDefinition = valueDefinitions[fromIndex];
    this.curtainStateService.openCurtain();
    moveItemInArray(valueDefinitions, fromIndex, toIndex);

    this.metricsService
      .moveField(this.valueDefinitionGroup.metric_id, this.valueDefinitionGroup.id, valueDefinition.id, toIndex + 1)
      .pipe(
        finalize(() => {
          this.updateFieldStatus(false);
          this.curtainStateService.closeCurtain();
        }),
      )
      .subscribe({
        next: (res: ApiResponse<Metric>) => {
          this.valueDefinitionGroup =
            res.data.value_definition_groups?.find((vdg) => vdg.id === this.valueDefinitionGroup.id) ||
            this.valueDefinitionGroup;
          this.metricStructureService.updateMetric(res.data);
          this.setSelectedItem(this.valueDefinitionGroup.value_definitions?.[toIndex]);
        },
        error: () => {
          moveItemInArray(valueDefinitions, toIndex, fromIndex);
        },
      });
  }

  private updateFieldStatus(updating: boolean): void {
    this.metricStructureService.updateIsMetricUpdating(updating);
  }
}
