import { Injectable } from '@angular/core';
import { Value, ValueGroup } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class FormatFieldUniqueIdService {
  public getFieldUniqueIdFromGroupAndValue(group: ValueGroup, value: Value): string {
    return this.generateFieldUniqueId(
      group.value_definition_group_id,
      group.position,
      value.value_definition_id,
      value.position,
      group.subposition,
      value.fiscal_year_period?.period,
    );
  }

  public getFieldUniqueIdFromParameters(
    valueDefinitionGroupId: string,
    groupPosition: number,
    valueDefinitionId: string,
    valuePosition: number,
    groupSubposition?: number | null,
    period?: string,
  ): string {
    return this.generateFieldUniqueId(
      valueDefinitionGroupId,
      groupPosition,
      valueDefinitionId,
      valuePosition,
      groupSubposition,
      period,
    );
  }

  private generateFieldUniqueId(
    valueDefinitionGroupId: string,
    groupPosition: number,
    valueDefinitionId: string,
    valuePosition: number,
    groupSubposition: number | null = 1,
    period: string = 'Y',
  ): string {
    return `${valueDefinitionGroupId}-${groupPosition}-${groupSubposition}-${valueDefinitionId}-${valuePosition}-${period}`;
  }
}
