import { Pipe, PipeTransform } from '@angular/core';
import { TreeTableDataNode } from '../../models';

@Pipe({ name: 'isNodeSelected' })
export class IsNodeSelectedPipe implements PipeTransform {
  transform<T>(selectedNodes: TreeTableDataNode<T>[], node: TreeTableDataNode<T>): boolean {
    if (!selectedNodes) {
      return false;
    }
    return selectedNodes.some((selectedNode) => selectedNode.id === node.id);
  }
}
