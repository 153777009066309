<div
  *ngIf="control"
  libFormField
  [size]="size"
  [required]="required"
  [control]="control"
  [readonly]="readonly"
  [labelPosition]="labelPosition"
  [dataTestId]="'date-field'"
>
  <lib-form-field-label [label]="label" [control]="control" [for]="_inputId"></lib-form-field-label>
  <mat-form-field class="w-100" floatLabel="auto">
    <input
      matInput
      [formControl]="tempControl"
      [matDatepicker]="picker"
      class="color-grey-900"
      [placeholder]="placeholder"
      [id]="_inputId"
      name="date-field"
      #focusElement
      libFocusState
      [focusStateControl]="control"
      [readonly]="readonly"
      [min]="minDate"
      [max]="maxDate"
      data-testid="date-field-input"
    />
    <mat-datepicker-toggle class="datepicker-toggle-padding" matSuffix [for]="picker" [disabled]="tempControl.disabled">
      <mat-icon matDatepickerToggleIcon svgIcon="calendar" class="datepicker-icon"></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker-toggle *ngIf="tempControl.value && !tempControl.disabled" matSuffix (click)="clearSelectedDate()">
      <mat-icon
        matDatepickerToggleIcon
        class="datepicker-icon"
        svgIcon="clear"
        data-testid="clearSelectedDate"
      ></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-error *ngFor="let error of tempControl.errors | errorKeys"> {{ errorMessages[error] }}</mat-error>
    <mat-error *ngIf="tempControl.errors?.maxDateRange">
      {{
        "Date must not be above maximum Date {maxDate}"
          | translate: { maxDate: tempControl.errors?.maxDateRange.maxDate }
      }}
    </mat-error>
    <mat-error *ngIf="tempControl.errors?.minDateRange">
      {{
        "Date must not be below minimum Date {minDate}"
          | translate: { minDate: tempControl.errors?.minDateRange.minDate }
      }}
    </mat-error>
  </mat-form-field>
</div>
