import { Metric, ValueDefinition } from './metrics';

export enum XBRLValidationTypeV1 {
  missingValue = 'missing_value',
  unsupportedUnit = 'unsupported_unit',
  wrongFieldType = 'wrong_field_type',
  wrongUnitOrFamily = 'wrong_unit_or_family',
}

export interface ExportXBRLTagsRequest {
  file_name: string;
  taxonomy_id: string;
  business_unit_id: string;
  fiscal_year: string;
  start: string;
  end: string;
}

export interface ValidateXBRLTagsExportItem {
  message: string;
  type: XBRLValidationTypeV1;
  metric: Metric;
  value_definition: ValueDefinition;
}

export interface ValidateXBRLTagsExportResponse {
  errors: ValidateXBRLTagsExportItem[];
  warnings: ValidateXBRLTagsExportItem[];
}
