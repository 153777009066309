<div class="legend">
  <div *ngFor="let item of data" class="legend-item">
    <div
      [ngStyle]="{ 'background-color': item.color }"
      class="legend-icon mr-1"
      [attr.data-testid]="'legend-icon-' + item.title + item.color"
    ></div>
    <p class="m-0">{{ item.title }}</p>
  </div>
</div>
