export { JobQueueStatus, JobQueueCategory };

enum JobQueueStatus {
  SUCCESS = 'done',
  ERROR = 'error',
  IN_PROGRESS = 'started',
  QUEUED = 'queued',
}

enum JobQueueCategory {
  create_report_questionnaire = 'create_report_questionnaire',
  questionnaire_export = 'questionnaire_export',
  framework_template_export = 'framework_template_export',
  custom_template_export = 'custom_template_export',
  export_report_questionnaire = 'export_report_questionnaire',
  submit_sp_report_questionnaire = 'submit_sp_report_questionnaire',
  delete_metric = 'delete_metric',
  activate_and_prefill_data_request = 'activate_and_prefill_data_request',
  activate_and_prefill_data_request_source = 'activate_and_prefill_data_request_source',
  create_metric_data_export = 'create_metric_data_export',
  create_metric_data_metric_group_export = 'create_metric_data_metric_group_export',
  create_metric_data_data_request_export = 'create_metric_data_data_request_export',
  submit_cdp_report_questionnaire = 'submit_cdp_report_questionnaire',
  consolidate_business_units = 'consolidate_business_units',
  search_and_create_metric_structure_export = 'search_and_create_metric_structure_export',
  search_and_create_metric_data_export = 'search_and_create_metric_data_export',
  benchmark_v2_export = 'benchmark_v2_export',
  create_metric_data_audit_export = 'create_metric_data_audit_export',
  source_comparison_v1_export = 'source_comparison_v1_export',
  data_table_v2_export = 'data_table_v2_export',
  import_integration_v1_data = 'import_integration_data',
  automatic_data_request_source_import = 'automatic_data_request_source_import',
  submit_data_request_source = 'submit_data_request_source',
  create_value_definition_frequencies_for_fiscal_year = 'create_value_definition_frequencies_for_fiscal_year',
  calculate_values = 'calculate_values',
  import_taxonomy_metrics = 'import_taxonomy_metrics',
  import_reference_core_metrics = 'import_reference_core_metrics',
  xbrl_tag_export = 'xbrl_tag_export',
}
