<div class="text-wrapper">
  <p
    [class.expanded]="isExpanded"
    class="more-text-container"
    [style.--max-lines]="maxLines"
    data-testid="more-text-testid"
  >
    <ng-container *ngIf="isHtml; else plainText">
      <span [innerHTML]="text | sanitizeHtml"></span>
    </ng-container>
    <ng-template #plainText>{{ text }}</ng-template>

    <ng-container *ngIf="needsTruncation && !isExpanded">
      <button lib-button buttonType="link" class="show-more" (click)="toggleText()">
        ...{{ "Show More" | translate }}
      </button>
    </ng-container>
  </p>
  <button lib-button buttonType="link" *ngIf="needsTruncation && isExpanded" class="show-less" (click)="toggleText()">
    {{ "Show Less" | translate }}
  </button>
</div>
