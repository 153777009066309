import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MetricApiService } from '../../services/types';
import { finalize, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { copyArrayItem } from '@angular/cdk/drag-drop';
import { CurtainStateService, TranslateService } from '../../services/common';
import {
  ActionItem,
  ApiError,
  ApiResponse,
  ConsolidationRules,
  ConsolidationTriggers,
  CreateMetricTableColumnDefinitionPayload,
  DefaultConsolidationRule,
  FileDocumentInterface,
  Metric,
  MetricCategory,
  MetricTableCalculationDefinition,
  MetricTableDefinition,
  MetricTableDefinitionUpdatePayload,
  MetricTableDefinitionUpsertPayload,
  Platforms,
  Status,
  UpdateMetricTableColumnDefinitionPayload,
  ValueDefinition,
  ValueDefinitionGroup,
  ValueDefinitionType,
} from '../../models';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ValueDefinitionDeleteErrorTranslation,
  ValueDefinitionValidationErrorTranslation,
  ValueDefinitionMoveErrorTranslation,
} from './metric-structure.translation';
import { ConfirmationDialogComponent, DialogsService } from '../../dialogs';
import {
  DefinitionValidationError,
  MetricStructureSelectable,
  MetricTableGroup,
  ValueDefinitionTemplateType,
} from '../models';
import { ErrorManagerService } from '../../error-manager';
import { MetricTableUtils } from '../../classes';
import keyBy from 'lodash/keyBy';

@Injectable()
export class MetricStructureStateService {
  static readonly newValueDefinitionGroup: ValueDefinitionGroup = {
    id: '',
    name: '',
    position: 0,
    indent: 0,
    metric_id: '',
    value_definitions: [],
    repeatable: false,
    active: true,
  };

  private _tipDisplayOptions: ActionItem[] = [
    {
      id: 'tip',
      title: this.translateService.instant('Tip'),
      icon: 'guidance',
      image: 'success', // used for color
    },
    {
      id: 'info',
      title: this.translateService.instant('Information'),
      icon: 'info',
      image: 'primary',
    },
    {
      id: 'warning',
      title: this.translateService.instant('Warning'),
      icon: 'warning',
      image: 'warning',
    },
    {
      id: 'none',
      icon: '',
      title: this.translateService.instant('None'),
      image: 'none',
    },
  ];

  private _cachedDocumentList$: BehaviorSubject<FileDocumentInterface[]> = new BehaviorSubject<FileDocumentInterface[]>(
    [],
  );
  readonly cachedDocumentList$: Observable<FileDocumentInterface[]>;

  private _selectedItem$: BehaviorSubject<MetricStructureSelectable | undefined> = new BehaviorSubject<
    MetricStructureSelectable | undefined
  >(undefined);
  readonly selectedItem$: Observable<MetricStructureSelectable | undefined>;

  private _metric$: BehaviorSubject<Metric | undefined> = new BehaviorSubject<Metric | undefined>(undefined);
  readonly metric$: Observable<Metric | undefined>;

  private _isMetricUpdating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isMetricUpdating$: Observable<boolean>;

  private _isCreatingField$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isCreatingField$: Observable<boolean>;

  private _canEditEveryMetrics$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly canEditEveryMetrics$: Observable<boolean>;

  private _canCreateCustomChoices$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly canCreateCustomChoices$: Observable<boolean>;

  private _isRepeatableGroup$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isRepeatableGroup$: Observable<boolean>;

  private _metricTableUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly metricTableUpdated$: Observable<boolean>;

  private _hostPlatform?: Platforms;

  get tipDisplayOptions(): ActionItem[] {
    return this._tipDisplayOptions;
  }

  get isAdmin(): boolean {
    return this._hostPlatform === Platforms.ADMIN;
  }

  get isPlatform(): boolean {
    return this._hostPlatform == Platforms.CLIENT;
  }

  constructor(
    private translateService: TranslateService,
    private metricsService: MetricApiService,
    private dialogsService: DialogsService,
    private curtainStateService: CurtainStateService,
    private errorManagerService: ErrorManagerService,
  ) {
    this.selectedItem$ = this._selectedItem$.asObservable();
    this.metric$ = this._metric$.asObservable();
    this.isMetricUpdating$ = this._isMetricUpdating$.asObservable();
    this.isCreatingField$ = this._isCreatingField$.asObservable();
    this.canEditEveryMetrics$ = this._canEditEveryMetrics$.asObservable();
    this.canCreateCustomChoices$ = this._canCreateCustomChoices$.asObservable();
    this.isRepeatableGroup$ = this._isRepeatableGroup$.asObservable();
    this.cachedDocumentList$ = this._cachedDocumentList$.asObservable();
    this.metricTableUpdated$ = this._metricTableUpdated$.asObservable();
  }

  private isCalculatedFieldInRepeatableGroup(value: ValueDefinition, group: ValueDefinitionGroup): boolean {
    return group.repeatable && value.type === ValueDefinitionType.calculated;
  }

  private displayCalculatedFieldInRepeatableGroupError(): void {
    this.dialogsService.error(
      this.translateService.instant('It is not possible to add a Number Calculation field into a Repeatable Group.'),
      this.translateService.instant('Validation rule violation'),
    );
  }

  public updateSelectedItem(value?: MetricStructureSelectable, selectedTab?: number): void {
    this._selectedItem$.next(
      value
        ? ({
            ...value,
            selectedTab,
          } as MetricStructureSelectable)
        : undefined,
    );
  }

  public updateMetricTableUpdated(): void {
    this._metricTableUpdated$.next(true);
  }

  public updateMetric(metric: Metric): void {
    this._metric$.next(metric);
  }

  public setIsCreatingField(isCreatingField: boolean): void {
    this._isCreatingField$.next(isCreatingField);
  }

  public setCanEditEveryMetrics(canEditEveryMetrics: boolean): void {
    this._canEditEveryMetrics$.next(canEditEveryMetrics);
  }

  public setCanCreateCustomChoices(canCreateCustomChoices: boolean): void {
    this._canCreateCustomChoices$.next(canCreateCustomChoices);
  }

  public updateIsMetricUpdating(isUpdating: boolean): void {
    this._isMetricUpdating$.next(isUpdating);
  }

  public updateIsRepeatableGroup(isRepeatableGroup: boolean): void {
    this._isRepeatableGroup$.next(isRepeatableGroup);
  }

  public setHostPlatform(hostPlatform: Platforms): void {
    this._hostPlatform = hostPlatform;
  }

  public addDocumentsToTheList(docs: FileDocumentInterface[]): void {
    const cachedDocslist: FileDocumentInterface[] = this._cachedDocumentList$.getValue();
    docs.forEach((doc) => {
      if (!cachedDocslist.some((d) => d.id == doc.id)) {
        cachedDocslist.push(doc);
      }
    });
    this._cachedDocumentList$.next(cachedDocslist);
  }

  public getDocumentById(docId: string): FileDocumentInterface | undefined {
    return this._cachedDocumentList$.getValue().find((doc) => doc.id === docId);
  }

  public getFieldPayload(
    valueDefinition: ValueDefinition,
    isUpdate = false,
    allowTypeChange = !isUpdate,
  ): Record<string, unknown> {
    return {
      ...(allowTypeChange && { type: valueDefinition.type }),
      type_details: valueDefinition.type_details,
      label: valueDefinition.label,
      short_label: valueDefinition.short_label,
      size: valueDefinition.size,
      newline: valueDefinition.newline,
      required: valueDefinition.required,
      hint: valueDefinition.hint,
      context: valueDefinition.context,
      consolidation_rule: valueDefinition.consolidation_rule,
      consolidation_trigger:
        valueDefinition.consolidation_rule === ConsolidationRules.manual ? null : valueDefinition.consolidation_trigger,
      ...(!!valueDefinition.validators && {
        validators: valueDefinition.validators.map((x) => ({
          validator_type: x.validator_type,
          instructions: x.instructions,
        })),
      }),
      bypass_consolidation_levels:
        valueDefinition.bypass_consolidation_levels === null
          ? null
          : valueDefinition.bypass_consolidation_levels?.map(Number),
      ...(!isUpdate && { position: valueDefinition.position }),
    };
  }

  public dropElement(from: ValueDefinition[], to: ValueDefinition[], from_index: number, to_index: number): void {
    switch (from[from_index].id) {
      case ValueDefinitionTemplateType.category:
        break;
      case ValueDefinitionTemplateType.template:
        this.createFieldStructure(from, to, from_index, to_index);
        break;
      default:
        this.transferField(from, to, from_index, to_index);
        break;
    }
  }

  public createGroup(): void {
    const metric = this._metric$.value;
    if (metric) {
      const newValueDefinitionGroup = {
        ...MetricStructureStateService.newValueDefinitionGroup,
        metric_id: metric.id,
        value_definitions: [],
      };
      let newIndex: number = -1;
      if (!metric.value_definition_groups) {
        metric.value_definition_groups = [];
      }
      // Add group to metric if there are no groups
      if (!metric.value_definition_groups.length) {
        metric.value_definition_groups.push(newValueDefinitionGroup);
        newIndex = 0;
      } else {
        newIndex = metric.value_definition_groups.length;
        metric.value_definition_groups.splice(newIndex, 0, newValueDefinitionGroup);
        newValueDefinitionGroup.position = newIndex + 1;
      }
      this.setIsCreatingField(true);
      this.updateMetric({ ...metric });
      this.updateSelectedItem(newValueDefinitionGroup);
    }
  }

  private createFieldStructure(
    valueDefinitionTemplates: ValueDefinition[],
    valueDefinitions: ValueDefinition[],
    templateIndex: number,
    index: number,
  ): void {
    const metric = this._metric$.value;

    if (metric) {
      const valueDefinitionGroup: ValueDefinitionGroup | undefined = metric.value_definition_groups?.find(
        (x) => x.value_definitions == valueDefinitions,
      );
      if (valueDefinitionGroup) {
        if (this.isCalculatedFieldInRepeatableGroup(valueDefinitionTemplates[templateIndex], valueDefinitionGroup)) {
          this.displayCalculatedFieldInRepeatableGroupError();
          return;
        }

        copyArrayItem(valueDefinitionTemplates, valueDefinitions, templateIndex, index);
        const itemIndex = valueDefinitions.length > 1 ? index : 0;
        valueDefinitions[itemIndex].position = itemIndex + 1;
        valueDefinitions[itemIndex].value_definition_group_id = valueDefinitionGroup.id;

        this.setIsCreatingField(true);
        this.updateSelectedItem(valueDefinitions[itemIndex]);
        this.updateMetric({ ...metric });
      }
    }
  }

  private transferField(
    fromValueDefinitions: ValueDefinition[],
    toValueDefinitions: ValueDefinition[],
    fromIndex: number,
    toIndex: number,
  ): void {
    const metric = this._metric$.value;
    if (!metric) {
      return;
    }
    const valueDefinition: ValueDefinition | undefined = fromValueDefinitions[fromIndex];
    const toValueDefinitionGroup = metric.value_definition_groups?.find(
      (x) => x.value_definitions == toValueDefinitions,
    );

    if (!valueDefinition || !toValueDefinitionGroup) {
      return;
    }

    if (valueDefinition.published && !this.isFieldInGroup(valueDefinition, toValueDefinitionGroup)) {
      return this.displayPublishedFieldMoveError();
    }

    if (this.isCalculatedFieldInRepeatableGroup(valueDefinition, toValueDefinitionGroup)) {
      return this.displayCalculatedFieldInRepeatableGroupError();
    }

    this.updateIsMetricUpdating(true);
    this.curtainStateService.openCurtain();

    const position = toIndex + 1;
    this.moveFieldRequest(metric, valueDefinition, position, toValueDefinitionGroup)
      .pipe(
        finalize(() => {
          this.updateIsMetricUpdating(false);
          this.curtainStateService.closeCurtain();
        }),
        map((response: ApiResponse<Metric>) => response.data),
      )
      .subscribe({
        next: (metric: Metric) => {
          const transferredVD = (
            metric.value_definition_groups?.find((x) => x.id === toValueDefinitionGroup.id)
              ?.value_definitions as ValueDefinition[]
          )[toIndex];
          this.updateSelectedItem(transferredVD);
          this.updateMetric(metric);
        },
        error: (errorResponse: unknown) => {
          try {
            this.handleMoveValidationErrors(errorResponse as HttpErrorResponse);
          } catch (_) {
            this.errorManagerService.handleError(errorResponse as HttpErrorResponse);
          }
        },
      });
  }

  private isFieldInGroup(valueDefinition: ValueDefinition, group: ValueDefinitionGroup): boolean {
    return valueDefinition.value_definition_group_id == group.id;
  }

  private moveFieldRequest(
    metric: Metric,
    valueDefinition: ValueDefinition,
    position: number,
    targetGroup: ValueDefinitionGroup,
  ): Observable<ApiResponse<Metric>> {
    if (this.isPlatform || this.isRefV2MetricInCoreEnv()) {
      return this.moveField(metric, valueDefinition, position, targetGroup);
    } else {
      return this.destructiveMoveField(metric, valueDefinition, position, targetGroup);
    }
  }

  private moveField(
    metric: Metric,
    valueDefinition: ValueDefinition,
    position: number,
    targetGroup: ValueDefinitionGroup,
  ): Observable<ApiResponse<Metric>> {
    return this.metricsService.moveFieldFromGroup(
      metric.id,
      valueDefinition.value_definition_group_id,
      valueDefinition.id,
      position,
      targetGroup.id,
    );
  }

  private destructiveMoveField(
    metric: Metric,
    valueDefinition: ValueDefinition,
    position: number,
    targetGroup: ValueDefinitionGroup,
  ): Observable<ApiResponse<Metric>> {
    return this.metricsService
      .deleteField(metric.id, valueDefinition.value_definition_group_id, valueDefinition.id)
      .pipe(
        switchMap(() =>
          this.metricsService.createField(metric.id, targetGroup.id, {
            ...this.getFieldPayload(valueDefinition),
            position,
          }),
        ),
      );
  }

  public getMetricTableDefinition(metricId: string, tableId: string): Observable<MetricTableDefinition> {
    return this.metricsService.getMetricTable(metricId, tableId, true).pipe(map((result) => result.data));
  }

  public createMetricTable(payload?: MetricTableDefinitionUpsertPayload): void {
    if (!payload) {
      payload = this.createInitialMetricTablePayload();
    }
    const metricId = this._metric$.value?.id;
    if (metricId) {
      this.setIsCreatingField(true);
      this.updateIsMetricUpdating(true);
      this.metricsService
        .createMetricTable(metricId, payload)
        .pipe(
          map((response: ApiResponse<Metric>) => response.data),
          finalize(() => {
            this.updateIsMetricUpdating(false);
            this.setIsCreatingField(false);
          }),
        )
        .subscribe((metric: Metric) => {
          const newMetricTableId = this._getNewTableIdInMetric(this._metric$.value, metric);
          this.updateMetric(metric);
          if (newMetricTableId) {
            this._updateSelectedMetricTable(metric, newMetricTableId);
          }
        });
    }
  }

  private createInitialMetricTablePayload(): MetricTableDefinitionUpsertPayload {
    return {
      title: this.translateService.instant('Sample Table'),
      column_definitions: [
        {
          label: this.translateService.instant('Context Column Header'),
          type: ValueDefinitionType.label,
          position: 1,
          type_details: { value: 'Context Option 1' },
          options: ['Context Option 1'],
          validators: [],
          consolidation_rule: DefaultConsolidationRule,
        },
        {
          label: this.translateService.instant('Input Column Header'),
          type: ValueDefinitionType.number,
          position: 2,
          type_details: { family: 'general', units: 'default', max_decimals: 0 },
          options: [],
          validators: [],
          consolidation_rule: ConsolidationRules.sum,
          consolidation_trigger: ConsolidationTriggers.update_when_one_value,
        },
      ],
    };
  }

  public updateMetricTable(
    metricTableDefinition: MetricTableDefinition,
    payload: MetricTableDefinitionUpdatePayload,
  ): void {
    this.executeTableChange(
      metricTableDefinition,
      this.metricsService.updateMetricTable(metricTableDefinition.metric_id, metricTableDefinition.id, payload),
    );
  }

  public createMetricTableColumnDefinition(
    metricTableDefinition: MetricTableDefinition,
    payload: CreateMetricTableColumnDefinitionPayload,
    callback?: () => void,
  ): void {
    this.executeTableChange(
      metricTableDefinition,
      this.metricsService
        .createMetricTableColumnDefinition(metricTableDefinition.metric_id, metricTableDefinition.id, payload)
        .pipe(map(() => null)),
      callback,
    );
  }

  public deleteMetricTableColumnDefinition(metricTableDefinition: MetricTableDefinition, columnId: string): void {
    this.executeTableChange(
      metricTableDefinition,
      this.metricsService
        .deleteMetricTableColumnDefinition(metricTableDefinition.metric_id, metricTableDefinition.id, columnId)
        .pipe(map(() => null)),
    );
  }

  public deleteMetricTableColumnOption(
    metricTableDefinition: MetricTableDefinition,
    columnId: string,
    optionId: string,
  ): void {
    this.executeTableChange(
      metricTableDefinition,
      this.metricsService
        .deleteMetricTableColumnOption(metricTableDefinition.metric_id, metricTableDefinition.id, columnId, optionId)
        .pipe(map(() => null)),
    );
  }

  public updateMetricTableColumnDefinition(
    metricTableDefinition: MetricTableDefinition,
    columnId: string,
    payload: UpdateMetricTableColumnDefinitionPayload,
    callback?: () => void,
  ): void {
    this.executeTableChange(
      metricTableDefinition,
      this.metricsService
        .updateMetricTableColumnDefinition(metricTableDefinition.metric_id, metricTableDefinition.id, columnId, payload)
        .pipe(map(() => null)),
      callback,
    );
  }

  public moveMetricTableColumnDefinition(
    metricTableDefinition: MetricTableDefinition,
    columnId: string,
    position: number,
  ): void {
    this.executeTableChange(
      metricTableDefinition,
      this.metricsService
        .moveMetricTableColumnDefinition(metricTableDefinition.metric_id, metricTableDefinition.id, columnId, position)
        .pipe(map(() => null)),
    );
  }

  public deleteMetricTable(metricId: string, tableId: string): void {
    this.dialogsService
      .open(ConfirmationDialogComponent, {
        data: {
          title: this.translateService.instant('Delete table'),
          warningMsg: this.translateService.instant('Are you sure you wish to delete this table?'),
        },
      })
      .afterClosed()
      .pipe(
        takeWhile((result) => result?.status === Status.CONFIRMED),
        tap(() => this.updateIsMetricUpdating(true)),
        switchMap(() => this.metricsService.deleteMetricTable(metricId, tableId)),
        finalize(() => this.updateIsMetricUpdating(false)),
      )
      .subscribe({
        next: (result) => {
          this.updateMetric(result.data);
          if (this._selectedItem$.getValue()?.id === tableId) {
            this.updateSelectedItem(undefined);
          }
        },
        error: (errorResponse: unknown) => {
          try {
            this.handleDeleteValidationErrors(errorResponse as HttpErrorResponse);
          } catch (_) {
            this.errorManagerService.handleError(errorResponse as HttpErrorResponse);
          }
        },
      });
  }

  public createMetricTableTotal(metricId: string, tableId: string, payload: MetricTableCalculationDefinition) {
    return this.metricsService.createMetricTableTotal(metricId, tableId, payload).pipe(
      map((response: ApiResponse<Metric>) => response.data),
      tap((metric: Metric) => {
        this.updateMetric(metric);
        this._updateSelectedMetricTable(metric, tableId, true);
      }),
    );
  }

  public updateMetricTableTotal(metricId: string, tableId: string, payload: MetricTableCalculationDefinition) {
    return this.metricsService.updateMetricTableTotal(metricId, tableId, payload).pipe(
      map((response: ApiResponse<Metric>) => response.data),
      tap((metric: Metric) => {
        this.updateMetric(metric);
        this._updateSelectedMetricTable(metric, tableId, true);
      }),
    );
  }

  public deleteMetricTableTotal(metricId: string, tableId: string, totalId: string) {
    return this.metricsService.deleteMetricTableTotal(metricId, tableId, totalId).pipe(
      map((response: ApiResponse<Metric>) => response.data),
      tap((metric: Metric) => {
        this.updateMetric(metric);
        this._updateSelectedMetricTable(metric, tableId, true);
      }),
    );
  }

  public deactivateMetricTableTotal(metricId: string, tableId: string, totalId: string) {
    return this.metricsService.deactivateMetricTableTotal(metricId, tableId, totalId).pipe(
      map((response: ApiResponse<Metric>) => response.data),
      tap((metric: Metric) => {
        this.updateMetric(metric);
        this._updateSelectedMetricTable(metric, tableId, true);
      }),
    );
  }

  public moveMetricTableTotal(metricId: string, tableId: string, totalId: string, position: number) {
    return this.metricsService.moveMetricTableTotal(metricId, tableId, totalId, position).pipe(
      map((response: ApiResponse<Metric>) => response.data),
      tap((metric: Metric) => {
        this.updateMetric(metric);
        this._updateSelectedMetricTable(metric, tableId, true);
      }),
    );
  }

  public removeTemporaryField(valueDefinition: ValueDefinition, itemIndex: number): void {
    const metric = this._metric$.getValue();
    if (!metric) {
      return;
    }
    const valueDefinitionGroup = metric.value_definition_groups?.find(
      (valueDefinitionGroup: ValueDefinitionGroup) =>
        valueDefinitionGroup.id === valueDefinition.value_definition_group_id,
    );

    valueDefinitionGroup?.value_definitions?.splice(itemIndex, 1);
    this.updateMetric(metric);
  }

  public removeTemporaryGroup(itemIndex: number): void {
    const metric = this._metric$.getValue();
    if (metric) {
      metric.value_definition_groups?.splice(itemIndex, 1);
      this.updateMetric({ ...metric });
    }
  }

  public handleUpdateValidationErrors(errorResponse: HttpErrorResponse): void {
    const errorDetail: string | undefined = ((errorResponse.error as ApiResponse).errors as ApiError<string>[])[0]
      .detail;

    if (errorDetail) {
      const extractedErrors = ErrorManagerService.extractErrorsFromDetails<DefinitionValidationError>(errorDetail);

      const contradictedValidation = extractedErrors[0].detail?.contradicted_validation;
      if (contradictedValidation && ValueDefinitionValidationErrorTranslation[contradictedValidation]) {
        this.dialogsService.error(
          this.translateService.instant(
            'Please note that it is not possible to {validationContradiction} as there are saved values that do not comply with the update.',
            { validationContradiction: ValueDefinitionValidationErrorTranslation[contradictedValidation] },
          ),
          this.translateService.instant('Validation rule violation'),
        );
        return;
      }
    }
    throw new Error(`Can't handle this error`);
  }

  public handleDeleteValidationErrors(errorResponse: HttpErrorResponse): void {
    const error: ApiError<string> = ((errorResponse.error as ApiResponse).errors as ApiError<string>[])[0];
    let errorMessage: string | undefined = undefined;
    if (error.type === 'value_definition_is_referenced_in_calculation') {
      errorMessage = ValueDefinitionDeleteErrorTranslation[error.type];
    } else {
      if (error.detail) {
        const extractedErrors = ErrorManagerService.extractErrorsFromDetails<DefinitionValidationError>(error.detail);
        const contradictedValidation = extractedErrors[0].detail?.contradicted_validation;

        if (contradictedValidation) {
          errorMessage = ValueDefinitionDeleteErrorTranslation[contradictedValidation];
        }
      }
    }

    if (errorMessage) {
      this.dialogsService.error(
        this.translateService.instant(errorMessage),
        this.translateService.instant('Validation rule violation'),
      );
      return;
    }
    throw new Error(`Can't handle this error`);
  }

  public handleMoveValidationErrors(errorResponse: HttpErrorResponse): void {
    const error: ApiError<string> = ((errorResponse.error as ApiResponse).errors as ApiError<string>[])[0];
    if (!(error.type in ValueDefinitionMoveErrorTranslation)) {
      throw new Error(`Can't handle this error`);
    }
    const errorMessage = ValueDefinitionMoveErrorTranslation[error.type];
    this.displayValidationError(this.translateService.instant(errorMessage));
  }

  private displayPublishedFieldMoveError(): void {
    this.displayValidationError(
      this.translateService.instant('This value definition is already published and cannot be moved out of its group'),
    );
  }

  private displayValidationError(msg: string): void {
    this.dialogsService.error(msg, this.translateService.instant('Validation rule violation'));
  }

  private _getNewTableIdInMetric(oldMetric?: Metric, newMetric?: Metric): string | undefined {
    if (!oldMetric || !newMetric) {
      return undefined;
    }

    const oldMetricTableIds: string[] = Object.keys(keyBy(oldMetric.value_definition_groups, 'table_id'));
    const updatedMetricTableIds: string[] = Object.keys(keyBy(newMetric.value_definition_groups, 'table_id'));
    const newMetricTableIds: string[] = updatedMetricTableIds.filter((tableId) => !oldMetricTableIds.includes(tableId));

    return newMetricTableIds.length ? newMetricTableIds[0] : undefined;
  }

  private _updateSelectedMetricTable(metric: Metric, metricTableId: string, keepSelectedTab: boolean = false): void {
    this.updateSelectedItem(
      MetricTableUtils.mergeTableVDGIntoMetricTableGroup(
        metric.value_definition_groups?.filter((vdg) => vdg.table_id === metricTableId) ?? [],
      ) as MetricTableGroup,
      keepSelectedTab ? this._selectedItem$.getValue()?.selectedTab : undefined,
    );
  }

  public isRefV2MetricInCoreEnv(): boolean {
    return Boolean(this.isRefV2Metric() && this.isAdmin);
  }

  public isRefV2Metric(): boolean {
    const metric = this._metric$.value;
    return Boolean(metric?.reference_v2 && metric.category == MetricCategory.REFERENCE);
  }

  private executeTableChange(
    metricTableDefinition: MetricTableDefinition,
    observable: Observable<ApiResponse<Metric> | null>,
    callback?: () => void,
  ): void {
    /**
     * The api call that generates the observable must bypass the error interceptor
     * The error will be handled in this function instead. Otherwise 2 error modals will be triggered
     */
    this.updateIsMetricUpdating(true);
    observable
      .pipe(
        switchMap((res) =>
          res
            ? of(res)
            : this.metricsService.getMetric(metricTableDefinition.metric_id, {
                load_value_definition_groups: true,
                load_value_definitions: true,
                load_conditional_triggers: true,
                load_hidden_by_taxonomy: true,
              }),
        ),
        map((response) => response.data),
        finalize(() => this.updateIsMetricUpdating(false)),
      )
      .subscribe({
        next: (metric: Metric) => {
          if (this._metric$.value) {
            const updatedMetric = { ...this._metric$.value, value_definition_groups: metric.value_definition_groups };
            this.updateMetric(updatedMetric);
            this._updateSelectedMetricTable(updatedMetric, metricTableDefinition.id);
          }

          if (callback) {
            callback();
          }
        },
        error: (errorResponse: unknown) => {
          try {
            this.handleUpdateValidationErrors(errorResponse as HttpErrorResponse);
          } catch (error) {
            this.errorManagerService.handleError(errorResponse as HttpErrorResponse);
          }
        },
      });
  }
}
