<lib-item-card [item]="item" [menuItems]="menuItems" [disabled]="disabled" (menuAction)="menuAction.emit($event)">
  <ng-template #action>
    <div class="drag-handle"></div>
    <mat-icon svgIcon="vector" class="pi item-card-drag-icon" data-testid="itemCardDragHandle"></mat-icon>
  </ng-template>
  <ng-template #afterTitle>
    <ng-container
      *ngIf="afterTitleTmpl"
      [ngTemplateOutlet]="afterTitleTmpl"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-container>
  </ng-template>
  <ng-template #extraContent>
    <ng-container
      *ngIf="extraContentTmpl"
      [ngTemplateOutlet]="extraContentTmpl"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-container>
  </ng-template>
</lib-item-card>
