import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ApiResponse,
  ApplicationApiDefinition,
  Taxonomy,
  MinimalTaxonomyTree,
  FavoriteTaxonomy,
} from '../../../models';
import { ApiService } from '../../common';

@Injectable({
  providedIn: 'root',
})
export class ClientTaxonomiesService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.taxonomies;
  }

  getTaxonomy(taxonomyId: string, completeFramework = false): Observable<ApiResponse<Taxonomy>> {
    let params = new HttpParams();
    params = params.append('complete_framework', completeFramework);

    return this.apiService.get(`${this.servicePath}${this.resource}/taxonomies/${taxonomyId}`, { params });
  }

  getFavoriteTaxonomies(
    completeFrameworks: boolean = false,
    frequency_code?: string,
  ): Observable<ApiResponse<FavoriteTaxonomy[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/favorite_taxonomies`, {
      complete_frameworks: completeFrameworks,
      frequency_code: frequency_code ?? null,
    });
  }

  getNonFavoriteTaxonomies(
    completeFrameworks: boolean = false,
    frequency_code?: string,
  ): Observable<ApiResponse<Taxonomy[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/non_favorite_taxonomies`, {
      complete_frameworks: completeFrameworks,
      frequency_code: frequency_code ?? null,
    });
  }

  getFrameworkTaxonomies(
    frameworkId: string,
    deepest_level_node?: string,
  ): Observable<ApiResponse<MinimalTaxonomyTree[]>> {
    let params = new HttpParams();
    if (deepest_level_node) {
      params = params.append('deepest_level_node', deepest_level_node);
    }

    return this.apiService.get(`${this.servicePath}${this.resource}/framework/${frameworkId}/taxonomies`, { params });
  }

  updateFavouriteTaxonomies(
    taxonomy_ids: string[],
    fiscal_year_id: string,
  ): Observable<ApiResponse<MinimalTaxonomyTree[]>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/fiscal_years/${fiscal_year_id}/taxonomies`, {
      taxonomy_ids,
    });
  }
}
