export enum ButtonTypeEnum {
  success = 'success',
  primary = 'primary',
  primary_v2 = 'primary_v2',
  link = 'link',
  danger = 'danger',
  warning = 'warning',
  medium = 'medium',
  color_link = 'color_link',
  cancel = 'cancel',
  cancel_with_border = 'cancel_with_border',
  light = 'light',
  stroked = 'stroked',
  stroked_light = 'stroked-light',
  browse = 'browse',
  inherit = 'inherit',
  icon = 'icon',
  icon_circle = 'icon_circle',
  soft_edge = 'soft_edge',
}
