import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { FormControl } from '@angular/forms';
import { OptionListOption } from '../../../models';
import { AlertsService, TranslateService } from '../../../services/common';
@Component({
  selector: 'lib-options-list-edit-table',
  templateUrl: './options-list-edit-table.component.html',
  styleUrls: ['./options-list-edit-table.component.scss'],
})
export class OptionsListEditTableComponent {
  @Input() nonDeletableColumnIds: string[] = [];
  @Input() options: OptionListOption[] = [];
  @Input() editable: boolean = false;
  @Input() editingOptionIndex: number = -1;
  @Input() isMetricUpdating: boolean = false;
  @Output() optionsChange = new EventEmitter<OptionListOption[]>();
  @Output() add = new EventEmitter<OptionListOption>();
  @Output() edit = new EventEmitter<OptionListOption>();
  @Output() delete = new EventEmitter<OptionListOption>();
  @Output() move = new EventEmitter<OptionListOption>();

  @ViewChild('choiceContent') choiceContent?: ElementRef<HTMLDivElement>;

  activeOption?: string = undefined;

  public editableOptionControl: FormControl = new FormControl<string>('', { nonNullable: true });

  constructor(
    private alertsService: AlertsService,
    private translateService: TranslateService,
  ) {}

  addOption(event: Event): void {
    const input = event.target as HTMLInputElement;
    const trimmedValue = input.value.trim();

    if (!trimmedValue) {
      return;
    }

    if (this.idDuplicateOption(trimmedValue, this.options)) {
      this.duplicateOptionAlert(trimmedValue);
    } else {
      input.value = '';
      const newOption = { label: trimmedValue };
      this.add.emit(newOption);
      this.optionsChange.emit([...this.options, newOption]);
    }

    setTimeout(() => {
      this.choiceContent?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, 0);
  }

  enableEditOption(index: number): void {
    this.editableOptionControl.setValue(this.options[index].label);
    this.editingOptionIndex = index;
  }

  editOption(): void {
    const currentOption = this.options[this.editingOptionIndex];
    const updateOption: string = this.editableOptionControl.value.trim();

    if (updateOption && updateOption !== currentOption.label) {
      const newOptions = this.options.slice();

      if (this.idDuplicateOption(updateOption, newOptions)) {
        this.duplicateOptionAlert(updateOption);
      } else {
        const editedOption = {
          id: currentOption.id,
          label: updateOption || currentOption.label,
        };
        newOptions[this.editingOptionIndex] = editedOption;
        this.edit.emit(editedOption);
        this.optionsChange.emit(newOptions);
      }
    }

    this.editingOptionIndex = -1;
    this.editableOptionControl.reset();
  }

  removeOption(choice: string): void {
    this.delete.emit(this.options.find((o) => o.label === choice));
    this.optionsChange.emit(this.options.filter((o) => o.label !== choice));
  }

  moveOption(choice: string, direction: 'up' | 'down'): void {
    const index: number = this.options.findIndex((o) => o.label === choice);
    const option = this.options.find((o) => o.label === choice);
    const newOptions = [...this.options];
    let position = index;

    if (!option) {
      return;
    }

    switch (direction) {
      case 'up':
        position -= 1;
        newOptions[index] = newOptions[position];
        newOptions[position] = { ...option, label: choice };
        break;
      case 'down':
        position += 1;
        newOptions[index] = newOptions[position];
        newOptions[position] = { ...option, label: choice };
        break;
    }

    this.move.emit({ ...option, position: position + 1 });
    this.optionsChange.emit(newOptions);
  }

  isFirstOption(choice: string): boolean {
    return this.options.findIndex((o) => o.label === choice) === 0;
  }

  isLastOption(choice: string): boolean {
    return this.options.findIndex((o) => o.label === choice) === this.options.length - 1;
  }

  private idDuplicateOption(newLabel: string, options: OptionListOption[]): boolean {
    const existingOptionLabels: string[] = options.map((option) => option.label);
    return existingOptionLabels.includes(newLabel);
  }

  private duplicateOptionAlert(optionLabel: string): void {
    this.alertsService.error(this.translateService.instant('Option: {option} already exists', { option: optionLabel }));
  }
}
