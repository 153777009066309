import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { EditService } from '@syncfusion/ej2-angular-grids';
import {
  DataRequestSourceStatus,
  DataRequestUserResponsibility,
  DataRequestValueGroupSetStatus,
  FocusedFieldAction,
  getConsolidatedInfo,
  isDBConsolidationEnabled,
  MetricTableDefinition,
  PlatformValueGroupSetStatus,
  SOURCE_CONFIGURATION,
  Value,
  ValueDefinitionType,
} from '../../../models';
import { TableFormGroup } from '../../models/tableFormGroup';
import { ValueGroupFormGroup } from '../../models/valueGroupFormGroup';
import { UpsertValueGroup } from '../../models/upsertValue';
import { ValueFormControl } from '../../models/valueFormControl';
import { iif, map, merge, Observable, of, Subject, Subscription, take, takeUntil } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { BaseMetricEditorFormStateService } from '../../services/base-metric-editor-form-state/base-metric-editor-form-state.service';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { ResetValueEventWithoutVgsetId } from '../../models/resetValueEvent';
import { FormUtils } from '../../../classes';
import { isNullishValue } from '../../utils/valueUtils';
import { FormatFieldUniqueIdPipe } from '../../../pipes';
import { partition } from 'lodash';
import { isTableContextValue } from './utils/is-table-context-value';
import { DEFAULT_DOCUMENT_CONTEXT, DocumentContext } from '../../models/documentContext';
import { FormatFieldUniqueIdService } from '../../services/format-field-unique-id/format-field-unique-id.service';

@Component({
  selector: 'lib-metric-editor-table-handler',
  templateUrl: './metric-editor-table-handler.component.html',
  styleUrls: ['./metric-editor-table-handler.component.scss'],
  providers: [EditService, FormatFieldUniqueIdPipe],
})
export class MetricEditorTableHandlerComponent implements OnInit, OnChanges, OnDestroy {
  @Input({ required: true }) tableFormGroup!: TableFormGroup;
  @Input({ required: true }) isConsolidatedBU!: boolean;
  @Input({ required: true }) sourceConfiguration!: SOURCE_CONFIGURATION;
  @Input() documentContext: DocumentContext = DEFAULT_DOCUMENT_CONTEXT;
  @Input() indicatorId: string = '';
  @Input() vgsetId: string = '';
  @Input() displayFieldActions: boolean = false;
  @Input() userResponsibility?: DataRequestUserResponsibility;
  @Input() valueGroupSetStatus!: PlatformValueGroupSetStatus | DataRequestValueGroupSetStatus;
  @Input() dataRequestSourceStatus!: DataRequestSourceStatus;
  @Output() update: EventEmitter<UpsertValueGroup> = new EventEmitter<UpsertValueGroup>();
  @Output() resetValue: EventEmitter<ResetValueEventWithoutVgsetId> = new EventEmitter<ResetValueEventWithoutVgsetId>();

  readonly eValueDefinitionType = ValueDefinitionType;

  headerValues: Value[] = [];
  contextHeaderValues: Value[] = [];
  tableTitle: string = '';
  showTableTotalComputation: boolean = true;
  metricTableDefinition?: MetricTableDefinition;
  isFocusEnabled: boolean = false;
  consolidatedInfoMap: Map<string, string> = new Map();
  tableTotalFormGroup?: ValueGroupFormGroup;

  focusedField$: Observable<Value | undefined>;
  focusFieldUniqueId$: Observable<string>;
  valueChangeSubscription?: Subscription;
  private destroy$ = new Subject<void>();

  constructor(
    private baseMetricEditorFormStateService: BaseMetricEditorFormStateService,
    private formatFieldUniqueIdService: FormatFieldUniqueIdService,
  ) {
    this.showTableTotalComputation = this.baseMetricEditorFormStateService.getShowTableTotalComputation();
    this.focusedField$ = this.baseMetricEditorFormStateService.focusedField$;
    this.focusFieldUniqueId$ = this.baseMetricEditorFormStateService.focusFieldUniqueId$;
  }

  ngOnInit(): void {
    this.setTableDefinitions();
  }

  ngOnChanges(): void {
    this.handleTableTitle();
    this.handleHeaderValues();
    this.isFocusEnabled = this.baseMetricEditorFormStateService.enableFocus;
    this.consolidatedInfoMap = this.formatConsolidatedInfo();
    this.tableTotalFormGroup = this.tableFormGroup.getGroupFormGroupTableTotals();
    this.setupValueChangesSubscription();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private formatConsolidatedInfo(): Map<string, string> {
    const consolidatedInfoMap: Map<string, string> = new Map();

    if (!this.isConsolidatedBU) {
      return consolidatedInfoMap;
    }

    for (const headerValue of this.headerValues) {
      consolidatedInfoMap.set(headerValue.value_definition_id, getConsolidatedInfo(headerValue));
    }
    return consolidatedInfoMap;
  }

  private setTableDefinitions(): void {
    this.baseMetricEditorFormStateService
      .getMetricTableDefinition(this.tableFormGroup.getMetricId(), this.tableFormGroup.id)
      .pipe(take(1))
      .subscribe((metricTableDefinition) => {
        this.metricTableDefinition = metricTableDefinition;
      });
  }

  public selectFieldForFocus(
    groupFormGroup: ValueGroupFormGroup,
    value: Value,
    focusedFieldAction?: FocusedFieldAction,
  ): void {
    const group = groupFormGroup.valueGroupRef;
    if (value.type !== ValueDefinitionType.label) {
      this.baseMetricEditorFormStateService.setFocusFieldAndUniqueId(
        this.formatFieldUniqueIdService.getFieldUniqueIdFromGroupAndValue(group, value),
        value,
        group,
      );
      this.baseMetricEditorFormStateService.setFocusedFieldAction(focusedFieldAction);
    }
  }

  public isConsolidationEnabled(sourceConfiguration: SOURCE_CONFIGURATION, value: Value): boolean {
    const supportConsolidation = isDBConsolidationEnabled(sourceConfiguration);
    const businessUnitLevel = this.tableFormGroup.businessUnitLevel();
    let bypassConsolidation = false;

    bypassConsolidation =
      businessUnitLevel !== undefined && !!value.bypass_consolidation_levels?.includes(businessUnitLevel);

    return supportConsolidation && !bypassConsolidation;
  }

  private setupValueChangesSubscription(): void {
    this.valueChangeSubscription?.unsubscribe();

    this.valueChangeSubscription = this.mergeUpdates(this.tableFormGroup)
      .pipe(
        mergeMap(([valueFormControl, valueGroupFormGroup]) =>
          iif(
            () => !isNullishValue(valueFormControl.value),
            this.handleUpdateValue(valueFormControl, valueGroupFormGroup),
            this.handleImplicitResetValue(valueFormControl, valueGroupFormGroup),
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private handleUpdateValue(
    valueFormControl: ValueFormControl,
    valueGroupFormGroup: ValueGroupFormGroup,
  ): Observable<void> {
    return of(undefined).pipe(
      filter(() => valueFormControl.valid),
      tap(() => this.update.emit(valueGroupFormGroup.toUpsertValueGroup([valueFormControl.toUpsertValue()]))),
    );
  }

  private handleImplicitResetValue(
    valueFormControl: ValueFormControl,
    valueGroupFormGroup: ValueGroupFormGroup,
  ): Observable<void> {
    return of(undefined).pipe(
      filter(() => !FormUtils.isNullOrEmpty(valueFormControl.valueRef.id)),
      tap(() => this.resetValue.emit({ valueGroupId: valueGroupFormGroup.id, valueId: valueFormControl.valueRef.id })),
    );
  }

  private handleTableTitle(): void {
    this.tableTitle = this.tableFormGroup.tableGroupRef.valueGroups[0].label ?? '';
  }

  private handleHeaderValues(): void {
    [this.contextHeaderValues, this.headerValues] = partition(
      this.tableFormGroup.tableGroupRef.valueGroups[0].values,
      (v) => isTableContextValue(v),
    );
  }

  private mergeUpdates(form: UntypedFormGroup): Observable<[ValueFormControl, ValueGroupFormGroup]> {
    return merge(
      ...Object.keys(form.controls).map((controlName: string) => {
        const control = form.get(controlName) as ValueFormControl | ValueGroupFormGroup;
        return control instanceof UntypedFormGroup
          ? this.mergeUpdates(control)
          : control.valueChanges.pipe(map(() => [control, form] as [ValueFormControl, ValueGroupFormGroup]));
      }),
    );
  }

  public filterLabelTypeValue(item: ValueFormControl): boolean {
    return isTableContextValue(item.valueRef);
  }

  public filterValuesExceptLabelType(item: ValueFormControl): boolean {
    return !isTableContextValue(item.valueRef);
  }

  protected readonly FocusedFieldAction = FocusedFieldAction;
  protected readonly DataRequestUserResponsibility = DataRequestUserResponsibility;
}
