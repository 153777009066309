/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { Pipe, PipeTransform } from '@angular/core';
import { KeyValue, KeyValuePipe } from '@angular/common';

const keepOrder = (a: any, _b: any) => a;

// This pipe uses the angular keyvalue pipe. but doesn't change order.
@Pipe({
  name: 'defaultOrderKeyvalue',
})
export class DefaultOrderKeyvaluePipe extends KeyValuePipe implements PipeTransform {
  transform<K, V>(value: ReadonlyMap<K, V>, ...args: any[]): Array<KeyValue<K, V>>;
  transform<K extends number, V>(value: Record<K, V>, ...args: any[]): Array<KeyValue<string, V>>;
  transform<K extends string, V>(value: Record<K, V> | ReadonlyMap<K, V>, ...args: any[]): Array<KeyValue<K, V>>;
  transform(value: null | undefined, ...args: any[]): null;
  transform<K, V>(value: ReadonlyMap<K, V> | null | undefined, ...args: any[]): Array<KeyValue<K, V>> | null;
  transform<K extends number, V>(
    value: Record<K, V> | null | undefined,
    ...args: any[]
  ): Array<KeyValue<string, V>> | null;
  transform<K extends string, V>(
    value: Record<K, V> | ReadonlyMap<K, V> | null | undefined,
    ...args: any[]
  ): Array<KeyValue<K, V>> | null;
  transform(value: any, ..._args: any[]): any {
    return super.transform(value, keepOrder);
  }
}
