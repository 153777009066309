<div class="card-list pt-2" *ngIf="cardListView$ | async as view">
  <p class="pl-2 selected-count">{{ "{count} Selected" | translate: { count: view.count || ("None" | translate) } }}</p>
  <ul (libScrollToBottom)="onLoadMore()" class="card-list-scroll" data-testid="card-list-scroll">
    <li *ngFor="let item of items; trackBy: trackById" [attr.aria-label]="item.title">
      <lib-select-item-card
        [item]="item"
        [menuItems]="menuItems[item.id]"
        [disabled]="view.disabledMap[item.id]"
        [selected]="view.selectedMap[item.id] || (markDisabledSelected && view.disabledMap[item.id])"
        (menuAction)="action.emit($event)"
        (selectChange)="onSelectChange(item, $event)"
      >
        <ng-template #extraContent let-item>
          <ng-container
            *ngIf="extraContentTmpl"
            [ngTemplateOutlet]="extraContentTmpl"
            [ngTemplateOutletContext]="{ $implicit: item }"
          ></ng-container>
        </ng-template>
      </lib-select-item-card>
    </li>

    <lib-spinner *ngIf="isLoading"></lib-spinner>
  </ul>
</div>
