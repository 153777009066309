import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TabView } from '../../../models';

@Component({
  selector: 'lib-tab-view-panel',
  templateUrl: './tab-view-panel.component.html',
  styleUrls: ['./tab-view-panel.component.scss'],
})
export class TabViewPanelComponent {
  @Input({ required: true }) tabs!: TabView[];
  @Input() selectedIndex = 0;

  @Output() selectedIndexChange = new EventEmitter<number>();
}
