import { Component, Input, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'lib-show-more-text',
  templateUrl: './show-more-text.component.html',
  styleUrls: ['./show-more-text.component.scss'],
})
export class ShowMoreTextComponent implements AfterViewInit {
  @Input() text: string = '';
  @Input() maxLines: number = 3;
  @Input() isHtml: boolean = false;

  isExpanded: boolean = false;
  needsTruncation: boolean = false;

  constructor(private elRef: ElementRef) {}

  ngAfterViewInit() {
    const textElement = this.elRef.nativeElement.querySelector('.more-text-container');
    if (textElement.scrollHeight > textElement.clientHeight) {
      this.needsTruncation = true;
    }
  }

  toggleText() {
    this.isExpanded = !this.isExpanded;
  }
}
