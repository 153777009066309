<p-tabView class="safe-anchor" [activeIndex]="selectedIndex" (activeIndexChange)="selectedIndexChange.emit($event)">
  <p-tabPanel
    *ngFor="let tab of tabs; let index = index"
    [header]="tab.customHeader ? '' : tab.title"
    [selected]="selectedIndex === index"
  >
    <ng-template *ngIf="tab.customHeader" pTemplate="header">
      <ng-container *ngIf="tab.customHeader" [ngTemplateOutlet]="tab.customHeader"></ng-container>
    </ng-template>

    <ng-template pTemplate="content">
      <ng-container *ngIf="tab.content" [ngTemplateOutlet]="tab.content"></ng-container>
    </ng-template>
  </p-tabPanel>
</p-tabView>
