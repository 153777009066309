<ng-container *ngIf="!isLoading; else dummyTable">
  <p-table
    class="table-grid-container"
    [value]="values"
    [columns]="columns"
    [scrollable]="true"
    [lazy]="isPaginable"
    scrollHeight="flex"
    [resizableColumns]="isResizable"
    columnResizeMode="expand"
    [dataKey]="uniquenessRowProperty"
    [customSort]="isCustomSort"
    [selectionMode]="rowGroupingProperties?.length ? undefined : 'single'"
    [styleClass]="withGridLines ? 'p-datatable-gridlines' : ''"
    [rowGroupMode]="rowGroupingProperties?.length ? 'rowspan' : undefined"
    [groupRowsBy]="rowGroupingProperties?.[0] ?? undefined"
    (sortFunction)="sortColumn($event)"
    (onLazyLoad)="sortPaginatedColumn($event)"
    (onRowReorder)="reorderRow($event)"
    (onRowSelect)="clickRow($event)"
  >
    <ng-template *ngIf="tableTitle" pTemplate="caption">
      <div class="captionTitle">
        {{ tableTitle }}
      </div>
    </ng-template>

    <ng-template *ngIf="withHeader" pTemplate="header" let-columns>
      <tr
        lib-table-header-row
        [columns]="columns"
        [isExpandable]="isExpandable"
        [isResizable]="isResizable"
        [isRowReorderable]="isRowReorderable"
        [withHeader]="withHeader"
        [actionMenuItems]="actionMenuItems"
        [horizontalPaginationConfig]="horizontalPaginationConfig"
        (horizontalPageChanged)="horizontalPageChanged.emit($event)"
      ></tr>
    </ng-template>

    <ng-template
      pTemplate="body"
      let-rowData
      let-columns="columns"
      let-expanded="expanded"
      let-index="rowIndex"
      let-rowgroup="rowgroup"
      let-rowspan="rowspan"
    >
      <tr
        lib-table-body-row
        [pReorderableRow]="index"
        [pSelectableRow]="rowData"
        [columns]="columns"
        [rowData]="rowData"
        [isRowReorderable]="isRowReorderable"
        [isExpandable]="isExpandable"
        [isExpanded]="expanded"
        [actionMenuItems]="actionMenuItems"
        [defaultEmptyValue]="defaultEmptyValue"
        [isWrapping]="isWrapping"
        [rowgroup]="rowgroup"
        [rowspan]="rowspan"
        [rowGroupingProperties]="rowGroupingProperties"
      ></tr>
    </ng-template>

    <ng-template *ngIf="isExpandable && expandedRowConfig" pTemplate="rowexpansion" let-rowData>
      <tr>
        <td [attr.colspan]="columns.length + 1 + (isRowReorderable ? 1 : 0) + (actionMenuItems?.length ? 1 : 0)">
          <lib-table-grid-expanded-row [row]="rowData" [config]="expandedRowConfig"></lib-table-grid-expanded-row>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>

<ng-template #dummyTable>
  <lib-dummy [presentation]="ePresentation.matTable" data-testid="dummy-matTable"></lib-dummy>
</ng-template>

<lib-table-paginator
  *ngIf="isPaginable"
  [totalItemCount]="totalItemCount"
  [pageSize]="pageSize"
  [withPaginationAlternatives]="withPaginationAlternatives"
  [showCurrentPageReportTemplateText]="showCurrentPageReportTemplateText"
  (pageChanged)="pageChanged.emit($event)"
></lib-table-paginator>
