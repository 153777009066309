import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { difference } from 'lodash';
import { FeatureFlagService } from '../feature-flag.service';
import { FeatureFlagKeys } from '../models';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard {
  constructor(
    private featureFlagService: FeatureFlagService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const requiredFeatureFlags: FeatureFlagKeys[] | undefined = route.data['requiredFeatureFlags'];
    const hiddenWhenEnabled: FeatureFlagKeys[] | undefined = route.data['hiddenWhenEnabled'];
    const featureFlagRedirect: string = (route.data['featureFlagRedirect'] as string) || '**';

    return this.featureFlagService.areFeatureFlagsLoaded$().pipe(
      first((isClientConfigReady: boolean) => isClientConfigReady),
      map(() =>
        this.checkIfAllFFsEnablesRoute(requiredFeatureFlags, hiddenWhenEnabled)
          ? true
          : this.router.createUrlTree([featureFlagRedirect]),
      ),
    );
  }

  private checkIfAllFFsEnablesRoute(
    requiredFeatureFlags?: FeatureFlagKeys[],
    hiddenWhenEnabled?: FeatureFlagKeys[],
  ): boolean {
    if (!requiredFeatureFlags && !hiddenWhenEnabled) {
      return true;
    }
    const enabledFFs = this.featureFlagService.getEnabledFeatureFlags(requiredFeatureFlags ?? []);
    const disabledFFs = difference(requiredFeatureFlags, enabledFFs);

    if (hiddenWhenEnabled?.length) {
      const enabledAndMarkedHidden = enabledFFs.some((ff) => hiddenWhenEnabled.includes(ff));
      if (enabledAndMarkedHidden) {
        return false;
      }

      return disabledFFs.every((ff) => hiddenWhenEnabled.includes(ff));
    }

    return requiredFeatureFlags?.length === enabledFFs.length;
  }
}
