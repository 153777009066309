export enum MESSAGE_TYPES {
  REPORT_START = 'platform.report.create.start',
  REPORT_ERROR = 'platform.report.create.error',
  REPORT_CREATED = 'platform.report.create.done',
  REPORT_QUEUED = 'platform.report.create.queued',
  PLATFORM_REPORT_EXPORT_START = 'platform.report.export.start',
  PLATFORM_REPORT_EXPORT_ERROR = 'platform.report.export.error',
  PLATFORM_REPORT_EXPORT_DONE = 'platform.report.export.done',
  PLATFORM_REPORT_EXPORT_QUEUED = 'platform.report.export.queued',
  PLATFORM_SP_REPORT_SUBMIT_START = 'platform.sp.report.submit.start',
  PLATFORM_SP_REPORT_SUBMIT_DONE = 'platform.sp.report.submit.done',
  PLATFORM_SP_REPORT_SUBMIT_ERROR = 'platform.sp.report.submit.error',
  PLATFORM_SP_REPORT_SUBMIT_QUEUED = 'platform.sp.report.submit.queued',
  PLATFORM_DATA_REQUEST_PREFILL_START = 'platform.data-request.prefill.start',
  PLATFORM_DATA_REQUEST_PREFILL_DONE = 'platform.data-request.prefill.done',
  PLATFORM_DATA_REQUEST_PREFILL_ERROR = 'platform.data-request.prefill.error',
  PLATFORM_DATA_REQUEST_PREFILL_QUEUED = 'platform.data-request.prefill.queued',
  PLATFORM_DATA_REQUEST_SOURCE_PREFILL_START = 'platform.data-request-source.prefill.start',
  PLATFORM_DATA_REQUEST_SOURCE_PREFILL_DONE = 'platform.data-request-source.prefill.done',
  PLATFORM_DATA_REQUEST_SOURCE_PREFILL_ERROR = 'platform.data-request-source.prefill.error',
  PLATFORM_DATA_REQUEST_SOURCE_PREFILL_QUEUED = 'platform.data-request-source.prefill.queued',
  PLATFORM_AUTOMATIC_DATA_REQUEST_SOURCE_IMPORT_QUEUED = 'platform.data-request-source.automatic-import.queued',
  PLATFORM_AUTOMATIC_DATA_REQUEST_SOURCE_IMPORT_START = 'platform.data-request-source.automatic-import.start',
  PLATFORM_AUTOMATIC_DATA_REQUEST_SOURCE_IMPORT_ERROR = 'platform.data_request_source.automatic-import.error',
  PLATFORM_AUTOMATIC_DATA_REQUEST_SOURCE_IMPORT_DONE = 'platform.data_request_source.automatic-import.done',
  PLATFORM_SUBMIT_DATA_REQUEST_SOURCE_QUEUED = 'platform.data-request-source.submit.queued',
  PLATFORM_SUBMIT_DATA_REQUEST_SOURCE_START = 'platform.data-request-source.submit.start',
  PLATFORM_SUBMIT_DATA_REQUEST_SOURCE_ERROR = 'platform.data-request-source.submit.error',
  PLATFORM_SUBMIT_DATA_REQUEST_SOURCE_DONE = 'platform.data-request-source.submit.done',
  PLATFORM_METRIC_DELETE_START = 'platform.metric.delete.start',
  PLATFORM_METRIC_DELETE_DONE = 'platform.metric.delete.done',
  PLATFORM_METRIC_DELETE_ERROR = 'platform.metric.delete.error',
  PLATFORM_EXPORT_INDICATOR_CREATE_START = 'platform.export.indicator.create.start',
  PLATFORM_EXPORT_INDICATOR_CREATE_DONE = 'platform.export.indicator.create.done',
  PLATFORM_EXPORT_INDICATOR_CREATE_ERROR = 'platform.export.indicator.create.error',
  PLATFORM_EXPORT_INDICATOR_CREATE_QUEUED = 'platform.export.indicator.create.queued',
  PLATFORM_EXPORT_METRIC_CREATE_QUEUED = 'platform.export.metric.create.queued',
  PLATFORM_EXPORT_METRIC_CREATE_START = 'platform.export.metric.create.start',
  PLATFORM_EXPORT_METRIC_CREATE_ERROR = 'platform.export.metric.create.error',
  PLATFORM_EXPORT_METRIC_CREATE_DONE = 'platform.export.metric.create.done',
  PLATFORM_EXPORT_FIELD_AUDIT_CREATE_START = 'platform.export.field.audit.create.start',
  PLATFORM_EXPORT_FIELD_AUDIT_CREATE_DONE = 'platform.export.field.audit.create.done',
  PLATFORM_EXPORT_FIELD_AUDIT_CREATE_ERROR = 'platform.export.field.audit.create.error',
  PLATFORM_EXPORT_FIELD_AUDIT_CREATE_QUEUED = 'platform.export.field.audit.create.queued',
  PLATFORM_DATA_REQUEST_INDICATOR_VALUE_GROUP_SET_UPDATE = 'platform.data-request.indicator.value-group-set.update',
  PLATFORM_DATA_REQUEST_SOURCE_UPDATE = 'platform.data-request.source.update',
  PLATFORM_DATA_REQUEST_INDICATOR_LINK_DEACTIVATED = 'platform.data-request.indicator-link.deactivated',
  PLATFORM_DATA_EXPORT_UPLOAD_DONE = 'platform.data-export.upload-data-export.done',
  PLATFORM_TEMPLATE_REPORT_EXPORT_START = 'platform.template-report.export.start',
  PLATFORM_TEMPLATE_REPORT_EXPORT_ERROR = 'platform.template-report.export.error',
  PLATFORM_TEMPLATE_REPORT_EXPORT_DONE = 'platform.template-report.export.done',
  PLATFORM_TEMPLATE_REPORT_EXPORT_QUEUED = 'platform.template-report.export.queued',
  PLATFORM_CDP_REPORT_SUBMIT_START = 'platform.cdp.report.submit.start',
  PLATFORM_CDP_REPORT_SUBMIT_DONE = 'platform.cdp.report.submit.done',
  PLATFORM_CDP_REPORT_SUBMIT_ERROR = 'platform.cdp.report.submit.error',
  PLATFORM_CDP_REPORT_SUBMIT_QUEUED = 'platform.cdp.report.submit.queued',
  PLATFORM_JOB_QUEUED = 'platform.job.queued',
  PLATFORM_CALCULATE_VALUES_START = 'platform.calculate-values.start',
  PLATFORM_CALCULATE_VALUES_DONE = 'platform.calculate-values.done',
  PLATFORM_CALCULATE_VALUES_ERROR = 'platform.calculate-values.error',
  PLATFORM_CONSOLIDATION_COMPANY_QUEUED = 'platform.consolidation.company.queued',
  PLATFORM_BENCHMARK_V2_EXPORT_START = 'platform.benchmark-v2.export.start',
  PLATFORM_BENCHMARK_V2_EXPORT_ERROR = 'platform.benchmark-v2.export.error',
  PLATFORM_BENCHMARK_V2_EXPORT_DONE = 'platform.benchmark-v2.export.done',
  PLATFORM_BENCHMARK_V2_EXPORT_QUEUED = 'platform.benchmark-v2.export.queued',
  PLATFORM_SOURCE_COMPARISON_EXPORT_START = 'platform.source-comparison-v1.export.start',
  PLATFORM_SOURCE_COMPARISON_EXPORT_ERROR = 'platform.source-comparison-v1.export.error',
  PLATFORM_SOURCE_COMPARISON_EXPORT_DONE = 'platform.source-comparison-v1.export.done',
  PLATFORM_SOURCE_COMPARISON_EXPORT_QUEUED = 'platform.source-comparison-v1.export.queued',
  PLATFORM_INTEGRATION_DATA_IMPORT_START = 'platform.integration-data-v1.import.start',
  PLATFORM_INTEGRATION_DATA_IMPORT_DONE = 'platform.integration-data-v1.import.done',
  PLATFORM_INTEGRATION_DATA_IMPORT_ERROR = 'platform.integration-data-v1.import.error',
  PLATFORM_DATA_TABLE_V2_EXPORT_START = 'platform.data-table-v2.export.start',
  PLATFORM_DATA_TABLE_V2_EXPORT_ERROR = 'platform.data-table-v2.export.error',
  PLATFORM_DATA_TABLE_V2_EXPORT_DONE = 'platform.data-table-v2.export.done',
  PLATFORM_DATA_TABLE_V2_EXPORT_QUEUED = 'platform.data-table-v2.export.queued',
  PLATFORM_FISCAL_YEAR_FRAMEWORK_TAXONOMY_PUBLISH_IN_PROGRESS = 'platform.fiscal-years.framework-taxonomy.publish.in-progress',
  PLATFORM_FISCAL_YEAR_FRAMEWORK_TAXONOMY_PUBLISH_SUCCESS = 'platform.fiscal-years.framework-taxonomy.publish.success',
  PLATFORM_FISCAL_YEAR_FRAMEWORK_TAXONOMY_PUBLISH_ERROR = 'platform.fiscal-years.framework-taxonomy.publish.error',
  PLATFORM_IMPORT_REFERENCE_CORE_METRICS_START = 'platform.import-reference-core-metrics.start',
  PLATFORM_IMPORT_REFERENCE_CORE_METRICS_DONE = 'platform.import-reference-core-metrics.done',
  PLATFORM_IMPORT_REFERENCE_CORE_METRICS_ERROR = 'platform.import-reference-core-metrics.error',
  PLATFORM_IMPORT_REFERENCE_CORE_METRICS_QUEUED = 'platform.import-reference-core-metrics.queued',
  PLATFORM_XBRL_TAG_EXPORT_START = 'platform.xbrl-tag.export.start',
  PLATFORM_XBRL_TAG_EXPORT_ERROR = 'platform.xbrl-tag.export.error',
  PLATFORM_XBRL_TAG_EXPORT_DONE = 'platform.xbrl-tag.export.done',
  PLATFORM_XBRL_TAG_EXPORT_QUEUED = 'platform.xbrl-tag.export.queued',
}
