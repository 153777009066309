import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum ImportReferenceCoreMetricsStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

@Injectable({
  providedIn: 'root',
})
export class WebsocketMessagingService {
  private _platformImportReferenceCoreMetricsStatus$: BehaviorSubject<ImportReferenceCoreMetricsStatus> =
    new BehaviorSubject<ImportReferenceCoreMetricsStatus>(ImportReferenceCoreMetricsStatus.COMPLETE);

  platformImportReferenceCoreMetricsStatus$: Observable<ImportReferenceCoreMetricsStatus> =
    this._platformImportReferenceCoreMetricsStatus$.asObservable();

  setPlatformImportReferenceCoreMetricsStatus(status: ImportReferenceCoreMetricsStatus): void {
    this._platformImportReferenceCoreMetricsStatus$.next(status);
  }
}
