import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map, Observable, Subscription, tap } from 'rxjs';
import { ConfirmationDialogComponent, DialogsService } from '../../../../../dialogs';
import {
  ActionItem,
  DialogResult,
  MetricTableCalculationDefinition,
  MetricTableCalculationTypeDetailFormulaOperation,
  MetricTableColumnDefinition,
  ResourceType,
  Status,
  Unit,
} from '../../../../../models';
import { SearchService } from '../../../../../search';
import { TranslateService } from '../../../../../services/common';
import { MetricTableTotalForm } from './form/metric-table-total-form';

export interface MetricTableTotalData {
  position: number;
  model?: MetricTableCalculationDefinition;
  metricTableInputColumns: MetricTableColumnDefinition[];
  metricTableContextColumns: MetricTableColumnDefinition[];
}

export enum MetricTableColumnType {
  INPUT = 'input',
  CONTEXT = 'context',
}

const DEFAULT_UNIT_FAMILY = 'general';
const DEFAULT_UNIT = 'default';

@Component({
  selector: 'lib-metric-table-total-dialog',
  templateUrl: './metric-table-total-dialog.component.html',
  styleUrls: ['./metric-table-total-dialog.component.scss'],
})
export class MetricTableTotalDialogComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line max-len
  public readonly eMetricTableCalculationTypeDetailFormulaOperation: typeof MetricTableCalculationTypeDetailFormulaOperation =
    MetricTableCalculationTypeDetailFormulaOperation;
  public readonly eMetricTableColumnType: typeof MetricTableColumnType = MetricTableColumnType;

  public tableTotalForm: MetricTableTotalForm;
  public metricTableInputColumns: MetricTableColumnDefinition[] = [];
  public metricTableContextColumns: MetricTableColumnDefinition[] = [];
  public selectableMetricTableContextColumns: MetricTableColumnDefinition[] = [];

  public operationChoices: ActionItem[] = [
    {
      id: MetricTableCalculationTypeDetailFormulaOperation.SUM,
      title: this.translateService.instant('SUM ='),
    },
    {
      id: MetricTableCalculationTypeDetailFormulaOperation.AVERAGE,
      title: this.translateService.instant('AVG ='),
    },
  ];

  public unitFamilies$?: Observable<ActionItem[]>;
  public unitOptions$?: Observable<ActionItem<Unit>[]>;
  readonly numericDecimalOptions = Array(11)
    .fill(null)
    .map((_, i) => ({ id: `${i}`, title: `${i}` })) as ActionItem[];

  private unitFamilySubscription?: Subscription;

  constructor(
    private translateService: TranslateService,
    private dialogsService: DialogsService,
    private dialogRef: MatDialogRef<MetricTableTotalData, DialogResult<MetricTableCalculationDefinition>>,
    private searchService: SearchService,
    @Inject(MAT_DIALOG_DATA) private data: MetricTableTotalData,
  ) {
    this.tableTotalForm = new MetricTableTotalForm(data.position, data.model);
    this.metricTableInputColumns = data.metricTableInputColumns;
    this.metricTableContextColumns = data.metricTableContextColumns;
    this.refreshSelectableContextColumns();
    this.initializeUnitsAndFamilyOptions();
  }

  public ngOnInit(): void {
    this.unitFamilySubscription = this.tableTotalForm.familyControl.valueChanges.subscribe({
      next: () => {
        this.tableTotalForm.unitsControl.setValue(null);
      },
    });
  }

  public ngOnDestroy(): void {
    this.unitFamilySubscription?.unsubscribe();
  }

  public addTableTotal(): void {
    this.dialogRef.close({
      status: Status.SUCCESS,
      data: this.tableTotalForm.toModel(),
    });
  }

  public onColumnDelete(index: number, columnType: MetricTableColumnType): void {
    this.dialogsService
      .open(ConfirmationDialogComponent, {
        data: {
          title: this.translateService.instant('Delete Column'),
          warningMsg: this.translateService.instant('Are you sure you wish to delete this Column?'),
        },
      })
      .afterClosed()
      .subscribe((result?: DialogResult) => {
        if (result?.status === Status.CONFIRMED) {
          if (columnType === MetricTableColumnType.INPUT) {
            this.tableTotalForm.removeInputColumnControl(index);
          } else {
            this.tableTotalForm.removeContextColumnControl(index);
            this.refreshSelectableContextColumns();
          }
        }
      });
  }

  public selectContextColumn(contextColumn: MetricTableColumnDefinition): void {
    this.tableTotalForm.addContextColumnControl({ column: contextColumn.label, values: [] });
    this.selectableMetricTableContextColumns = this.metricTableContextColumns.filter(
      (column) => column.id !== contextColumn.id,
    );
    this.refreshSelectableContextColumns();
  }

  public closeDialog(): void {
    this.dialogRef.close({ status: Status.CANCEL });
  }

  private initializeUnitsAndFamilyOptions(): void {
    this.unitFamilies$ = this.searchService.searchResources(ResourceType.unit, 'families').pipe(
      map((families) => families.map((family) => ({ id: family.item, title: family.item, item: family }))),
      tap((familes) => {
        const defaultFamily = familes.find((family) => family.title === DEFAULT_UNIT_FAMILY);
        if (!this.tableTotalForm.familyControl.value && defaultFamily) {
          this.tableTotalForm.familyControl.setValue(defaultFamily.id);
        }
      }),
    );

    this.unitOptions$ = this.searchService.searchResources(ResourceType.unit).pipe(
      map((units) => units.map((unit) => ({ id: unit.item.code, title: unit.item.label, item: unit.item }))),
      tap((units) => {
        const defaultUnit = units.find((unit) => unit.id === DEFAULT_UNIT);
        if (!this.tableTotalForm.unitsControl.value && defaultUnit) {
          this.tableTotalForm.unitsControl.setValue(defaultUnit.id);
        }
      }),
    );
  }

  private refreshSelectableContextColumns(): void {
    this.selectableMetricTableContextColumns = this.metricTableContextColumns.filter(
      (column: MetricTableColumnDefinition) => !this.tableTotalForm.containsContextColumn(column.label),
    );
  }
}
