import { Pipe, PipeTransform } from '@angular/core';
import { Value, ValueGroup } from '../../models';
import { FormatFieldUniqueIdService } from '../../metric-editor-form/services/format-field-unique-id/format-field-unique-id.service';

@Pipe({
  name: 'formatFieldUniqueId',
})
export class FormatFieldUniqueIdPipe implements PipeTransform {
  constructor(private readonly formatFieldUniqueIdService: FormatFieldUniqueIdService) {}

  transform(group: ValueGroup, value: Value): string {
    return this.formatFieldUniqueIdService.getFieldUniqueIdFromGroupAndValue(group, value);
  }
}
