<div class="item-card mt-1" [ngClass]="cardClasses" role="presentation" [attr.aria-label]="item.title">
  <div class="item-card-action">
    <ng-container
      *ngIf="actionTmpl"
      [ngTemplateOutlet]="actionTmpl"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-container>
  </div>
  <div class="item-card-details">
    <b *ngIf="item.title as title" class="text-clamp text-clamp-1 m-0" [title]="title">
      {{ title }}
      <ng-container
        *ngIf="afterTitleTmpl"
        [ngTemplateOutlet]="afterTitleTmpl"
        [ngTemplateOutletContext]="{ $implicit: item }"
      ></ng-container>
    </b>
    <p *ngIf="item.subtitle as subtitle" class="item-card-subtitle text-clamp text-clamp-2 m-0" [title]="subtitle">
      {{ subtitle }}
    </p>
    <ng-container
      *ngIf="extraContentTmpl"
      [ngTemplateOutlet]="extraContentTmpl"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-container>
  </div>
  <lib-popup-menu
    *ngIf="menuItems"
    class="item-card-menu"
    [disabled]="disabled"
    [menuItems]="menuItems"
    (action)="menuAction.emit({ action: $event, item })"
  ></lib-popup-menu>
</div>
