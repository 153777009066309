<div class="actionable-card">
  <div class="header">
    <p class="title">
      <span class="title-text" libTitleOnEllipsis [tolerance]="1">{{ item?.title }}</span>
    </p>
    <mat-icon
      class="external-link color-info-600"
      *ngIf="withExternalLink"
      svgIcon="external"
      (click)="externalLinkClick.emit(item)"
      data-testid="external-link"
    ></mat-icon>
  </div>
  <ng-content></ng-content>
</div>
