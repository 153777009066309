import { Component, Input, OnChanges } from '@angular/core';
import { PointOptionsObject, SeriesVariablepieOptions } from 'highcharts';

interface DonutChartLegendConfig {
  title: string;
  color: string;
}

@Component({
  selector: 'lib-donut-chart-legend',
  templateUrl: './donut-chart-legend.component.html',
  styleUrl: './donut-chart-legend.component.scss',
})
export class DonutChartLegendComponent implements OnChanges {
  @Input() chartOptions?: Highcharts.Options = undefined;

  data: DonutChartLegendConfig[] = [];

  ngOnChanges(): void {
    this.initData();
  }

  private initData(): void {
    const seriesData = this.chartOptions?.series?.[0] as SeriesVariablepieOptions;
    const colors = this.chartOptions?.colors as string[];
    if (seriesData) {
      this.data =
        seriesData.data?.map((x: PointOptionsObject, i) => ({
          title: `${String(x.name)} (${x.y}%)`,
          color: colors[i % colors.length],
        })) ?? [];
    }
  }
}
