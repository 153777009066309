import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ArrayPropertyExtractionPipe } from './array-property-extraction/array-property-extraction.pipe';
import { BooleanPipe } from './boolean/boolean.pipe';
import { DompurifyPipe } from './dompurify/dompurify.pipe';
import { ErrorKeysPipe } from './error-keys/error-keys.pipe';
import { FilterPipe } from './filter/filter.pipe';
import { FilterUnitsByFamilyPipe } from './filter-units-by-family/filter-units-by-family.pipe';
import { FormatFieldUniqueIdPipe } from './format-field-unique-id/format-field-unique-id.pipe';
import { FullNamePipe } from './fullname/fullname.pipe';
import { GetItemByIdPipe } from './get-item-by-id/get-item-by-id.pipe';
import { GetPipe } from './get/get.pipe';
import { IncludesPipe } from './includes/includes.pipe';
import { IsArrayWithMoreThanOneItemPipe } from './is-array/is-array.pipe';
import { JoinPipe } from './join/join.pipe';
import { MapPipe } from './map/map.pipe';
import { MetricTableTotalFormulaPipe } from './metric-table-total-formula/metric-table-total-formula.pipe';
import { MultiSwitchCasePipe } from './multi-switch-case/multi-switch-case.pipe';
import { ObjectAssignPipe } from './object-assign/object-assign.pipe';
import { ObjectEntriesPipe } from './object-entries/object-entries.pipe';
import { ObjectValuesPipe } from './object-values/object-values.pipe';
import { StatusTransitionPipe } from './status-transition/status-transition.pipe';
import { TemplateLinkPipe } from './template-link/template-link.pipe';
import { TimeAgoPipe } from './time-ago/time-ago.pipe';
import { UnitSymbolPipe } from './unit-symbol/unit-symbol.pipe';
import { UpdatedByUserPipe } from './updated-by/updated-by-user.pipe';
import { ExecuteFunctionOnValuePipe } from './execute-function-on-value/execute-function-on-value.pipe';
import { SanitizeHtmlPipe } from './sanitize-html/sanitize-html.pipe';
import { MetricTableCellValueDisplayPipe } from './metric-table-cell-value-display.pipe';
import { IntegrationTypePipe } from './integration-type/integration-type.pipe';
import { CastAsKeyOfPipe } from './cast-as-key-of/cast-as-key-of.pipe';
import { DashboardWidgetToGridsterItemPipe } from './dashboard-widget-to-grister-item/dashboard-widget-to-grister-item.pipe';
import { ValueGroupSetStatusTransitionUserPipe } from './value-group-set-status-transition-user/value-group-set-status-transition-user.pipe';
import { LastArrayElementPipe } from './last-array-element/last-array-element.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
import { HtmlToStringPipe } from './html-to-string/html-to-string.pipe';
import { WithLoadingPipe } from './loading/loading.pipe';
import { UpdatedByGHGIntegrationSystemPipe } from './updated-by/updated-by-ghg-integration-system.pipe';
import { VgsetStatusContextPipe } from './vgset-status-context/vgset-status-context.pipe';
import { FormatNumberBrowserLocalePipe } from './format-number-browser-locale/format-number-browser-locale.pipe';
import { SomePipe } from './some/some.pipe';
import { CanAddFieldPipe } from './can-add-field/can-add-field.pipe';
import { DefaultOrderKeyvaluePipe } from './default-order-keyvalue/default-order-keyvalue.pipe';
import { EnumTranslatePipe } from './enum-translate/enum-translate.pipe';
import { StringifyPipe } from './stringify/stringify.pipe';

export const PIPES = [
  ArrayPropertyExtractionPipe,
  BooleanPipe,
  DompurifyPipe,
  ErrorKeysPipe,
  FilterPipe,
  FilterUnitsByFamilyPipe,
  FormatFieldUniqueIdPipe,
  FullNamePipe,
  GetItemByIdPipe,
  GetPipe,
  IncludesPipe,
  IsArrayWithMoreThanOneItemPipe,
  JoinPipe,
  MapPipe,
  MetricTableTotalFormulaPipe,
  MultiSwitchCasePipe,
  ObjectAssignPipe,
  ObjectEntriesPipe,
  ObjectValuesPipe,
  StatusTransitionPipe,
  TemplateLinkPipe,
  TimeAgoPipe,
  UnitSymbolPipe,
  UpdatedByUserPipe,
  ExecuteFunctionOnValuePipe,
  SanitizeHtmlPipe,
  MetricTableCellValueDisplayPipe,
  IntegrationTypePipe,
  CastAsKeyOfPipe,
  DashboardWidgetToGridsterItemPipe,
  ValueGroupSetStatusTransitionUserPipe,
  LastArrayElementPipe,
  TruncatePipe,
  HtmlToStringPipe,
  WithLoadingPipe,
  UpdatedByGHGIntegrationSystemPipe,
  VgsetStatusContextPipe,
  FormatNumberBrowserLocalePipe,
  SomePipe,
  CanAddFieldPipe,
  DefaultOrderKeyvaluePipe,
  EnumTranslatePipe,
  StringifyPipe,
];

@NgModule({
  declarations: [...PIPES],
  imports: [CommonModule],
  exports: [...PIPES],
  providers: [UpdatedByUserPipe],
})
export class PipesModule {}
