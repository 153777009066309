import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ValidationErrors, Validators } from '@angular/forms';
import { ValueDefinitionSize } from '../../../models';
import { ValidationMessageService } from '../../../services/common';
import { TreeNode } from 'primeng/api';
import { TreeSelectControl } from './tree-select-control';
import { TreeSelectMultipleControl } from './tree-select-multiple-control';
import { TreeSelect } from 'primeng/treeselect';

let nextId = 0;

@Component({
  selector: 'lib-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
})
export class TreeSelectComponent<T> implements OnChanges, OnInit {
  @Input() control?: TreeSelectControl | TreeSelectMultipleControl;
  @Input() size: ValueDefinitionSize = ValueDefinitionSize.large;
  @Input() hint: string = '';
  @Input() options: TreeNode<T>[] = [];
  @Input() label: string = '';
  @Input() filter: boolean = false;
  @Input() filterBy: string = 'label';
  @Input() showClear: boolean = true;
  @Input() selectionMode: 'single' | 'checkbox' = 'single';
  @Input() messages: ValidationErrors = {};
  @Input() labelPosition: 'top' | 'left' = 'top';
  @Input() autofocus: boolean = false;
  @Input() showOnFocus: boolean = false;
  @Input() fixedSubscript: boolean = true;

  @ViewChild(TreeSelect) treeselect?: TreeSelect;

  readonly _labelId: string = `tree-select-input-${nextId++}`;

  public required: boolean = false;
  public errorMessages: ValidationErrors = {};

  constructor(private validationMessageService: ValidationMessageService) {}

  ngOnInit(): void {
    if (this.options.length === 1) {
      this.options[0].expanded = true;
    }
  }

  ngOnChanges(): void {
    this.initializeInput();
  }

  private initializeInput() {
    this.required = this.control?.hasValidator(Validators.required) ?? false;
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };
  }

  public setToTouched() {
    this.control?.markAsTouched();
  }

  public onFocus() {
    // overlayVisible is undefined on first load then true/false once it's been shown once
    if (this.showOnFocus && this.treeselect && this.treeselect.overlayVisible == undefined) {
      this.treeselect.show();
    }
  }
}
