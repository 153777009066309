import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { CardItem } from '../../../models';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'lib-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss'],
})
export class ItemCardComponent<T extends CardItem = CardItem, I extends MenuItem = MenuItem> {
  @Input({ required: true }) item!: T;
  @Input() menuItems?: I[];
  @Input() set disabled(value: boolean) {
    this.cardClasses = { ...this.cardClasses, disabled: value || false };
  }
  @Input() set checked(value: boolean) {
    this.cardClasses = { ...this.cardClasses, checked: value || false };
  }
  @Input() set extraClasses(values: Record<string, boolean>) {
    this.cardClasses = { ...this.cardClasses, ...values };
  }

  @ContentChild('action', { static: false }) actionTmpl?: TemplateRef<unknown>;
  @ContentChild('afterTitle', { static: false }) afterTitleTmpl?: TemplateRef<unknown>;
  @ContentChild('extraContent', { static: false }) extraContentTmpl?: TemplateRef<unknown>;
  @Output() menuAction = new EventEmitter<{ action: I; item: T }>();

  cardClasses: Record<string, boolean> = {};
}
