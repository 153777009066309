import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DataRequestSourceStatus, DataRequestStatus, DataRequestUserResponsibility } from '../../models';

export {
  DataRequestsRequestStatus,
  DataRequestsSourceStatus,
  DataRequestsUserResponsibility,
  DataRequestsParticipantsResponsibilityPlural,
};

const DataRequestsRequestStatus = {
  [DataRequestStatus.DRAFT]: _('Not started'),
  [DataRequestStatus.INACTIVE]: _('Inactive'),
  [DataRequestStatus.ACTIVE]: _('In progress'),
  [DataRequestStatus.IMPORTED]: _('Imported'),
  [DataRequestStatus.ARCHIVED]: _('Archived'),
};

const DataRequestsSourceStatus = {
  [DataRequestSourceStatus.NOT_STARTED]: _('Not started'),
  [DataRequestSourceStatus.STARTED]: _('In contribution'),
  [DataRequestSourceStatus.IN_REVIEW]: _('In review'),
  [DataRequestSourceStatus.IN_APPROVAL]: _('In approval'),
  [DataRequestSourceStatus.IMPORTED]: _('Imported'),
  [DataRequestSourceStatus.IMPORTING]: _('Importing'),
};

const DataRequestsUserResponsibility = {
  [DataRequestUserResponsibility.CONTRIBUTOR]: _('Contributor'),
  [DataRequestUserResponsibility.REVIEWER]: _('Reviewer'),
  [DataRequestUserResponsibility.APPROVER]: _('Approver'),
};

const DataRequestsParticipantsResponsibilityPlural = {
  [DataRequestUserResponsibility.CONTRIBUTOR]: _('Contributors'),
  [DataRequestUserResponsibility.REVIEWER]: _('Reviewers'),
  [DataRequestUserResponsibility.APPROVER]: _('Approvers'),
};
