<div #metricTable class="metric-table-container" [attr.data-testid]="'metric-table-' + metricTableGroup.table_id">
  <lib-metric-structure-table-details
    [metricTableGroup]="metricTableGroup"
    [isDeactivated]="(metricTableGroup | isDeactivated) || !!metricTableGroup.hidden_by_taxonomy"
  >
  </lib-metric-structure-table-details>

  <div
    class="overlay"
    [ngClass]="{
      active: isActive,
    }"
    (click)="setSelectedItem(metricTableGroup)"
    [attr.data-testid]="'overlay-' + metricTableGroup.table_id"
  >
    <div class="actions">
      <ng-container *ngIf="!(metricTableGroup.valueDefinitionGroups[0] | isEntityFromCoreRefV2Metric: metric)">
        <button class="btn btn-sm" data-testid="moveTableDown" *ngIf="canMoveDown" (click)="moveTableDown($event)">
          {{ "Move Down" | translate }}
        </button>
        <button class="btn btn-sm" data-testid="moveTableUp" *ngIf="canMoveUp" (click)="moveTableUp($event)">
          {{ "Move Up" | translate }}
        </button>
      </ng-container>
      <button
        *ngIf="
          (!isAdmin && !metricTableGroup.core_metric_table_definition_id) ||
          (isAdmin && !metricTableDefinition?.published)
        "
        class="btn btn-sm"
        (click)="deleteTable($event)"
      >
        {{ "Delete" | translate }}
      </button>

      <ng-container *ngIf="deactivationEnabled || (isAdmin && metricTableDefinition?.published)">
        <button
          *ngIf="
            (metricTableGroup | isDeactivated) ||
              (metricTableGroup.hidden_by_taxonomy && !metricTableGroup.ignore_taxonomies);
            else deactivate
          "
          class="btn btn-sm"
          (click)="activateMetricTableGroup()"
          aria-label="activate-table"
        >
          {{ "Activate" | translate }}
        </button>
        <ng-template #deactivate>
          <button aria-label="group-deactivation" class="btn btn-sm" (click)="deactivateMetricTableGroup()">
            {{ "Deactivate" | translate }}
          </button>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>

<div class="mt-5 table-totals-container">
  <div
    class="bkg-grey-50 p-2 color-primary-900 table-total-card"
    [ngClass]="{ inactive: !tableTotal.active }"
    *ngFor="let tableTotal of tableTotals"
  >
    <p>{{ tableTotal.label }}</p>
    <p>
      <span>{{ "Total renders here" | translate }}</span>
      <span *ngIf="tableTotal.type_details.units | unitSymbol as unit">
        {{ " (in " + unit + ")" }}
      </span>
    </p>
  </div>
</div>
