<lib-expandable-card
  [cardTitle]="'Reference Section' | translate"
  [cardSubtitle]="'Fields may be added to the Customizable Section below.' | translate"
  data-testid="reference-section"
>
  <div
    content
    class="p-2 mt-3 reference-section-content"
    cdkDropList
    [cdkDropListDisabled]="true"
    (cdkDropListEntered)="updateShowReferenceSectionWarning(true)"
    (cdkDropListExited)="updateShowReferenceSectionWarning(false)"
    (cdkDropListDropped)="updateShowReferenceSectionWarning(false)"
  >
    <div class="reference-section-container" [ngClass]="{ 'show-warning': showReferenceSectionWarning }">
      <ng-container
        [ngTemplateOutlet]="metricStructure"
        [ngTemplateOutletContext]="{
          groupsOrTables: (groupsOrTables | filter: filterOutReferenceVDGOrTable),
          canMoveDown: false,
          canMoveUp: false,
        }"
      ></ng-container>

      <div class="warning-message" *ngIf="showReferenceSectionWarning" data-testid="reference-section-warning">
        <div class="message">
          <div class="fx-col align-center">
            <mat-icon svgIcon="warning" class="color-warning"></mat-icon>
          </div>
          <div class="fx-col align-center mat-body color-grey-900 mt-5">
            <span [innerHTML]="'Fields may be added to the <b>Customizable Section</b> below.' | translate"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</lib-expandable-card>

<div class="mt-4">
  <lib-expandable-card
    [cardTitle]="'Customizable Section' | translate"
    [cardSubtitle]="'Add a Group to include fields in your metric. Tables can be added without a Group.' | translate"
    data-testid="customizable-section"
    class="mt-5 customizable-section-content"
    #customizableCard
  >
    <div content class="p-2 mt-3" data-testsid="customizable-section">
      <ng-container *ngIf="groupsOrTables | filter: filterOutCustomizableVDGOrTable as groups">
        <ng-container
          *ngIf="groups.length > 0; else emptyCustomizableSectionWarning"
          [ngTemplateOutlet]="metricStructure"
          [ngTemplateOutletContext]="{
            groupsOrTables: groups,
            overlayDisabled: false,
          }"
        ></ng-container>
      </ng-container>
    </div>
  </lib-expandable-card>
</div>

<ng-template
  #metricStructure
  let-groupsOrTables="groupsOrTables"
  let-canMoveDown="canMoveDown"
  let-canMoveUp="canMoveUp"
>
  <ng-container *ngFor="let valueDefinitionGroupOrTable of groupsOrTables; trackBy: trackId; let i = index">
    <lib-metric-structure-group-form
      *ngIf="!valueDefinitionGroupOrTable?.table_id"
      [valueDefinitionGroup]="$any(valueDefinitionGroupOrTable)"
      [index]="i"
      [canMoveDown]="canMoveDown || i < groupsOrTables.length - 1"
      [selectedItemId]="selectedItem?.id ?? ''"
      [canMoveUp]="canMoveUp || i > 0"
      [class]="'form-group-wrapper form-group-wrapper-' + $any(valueDefinitionGroupOrTable).indent"
      [metric]="metric"
      [indexOffSet]="coreGroupOrTableLength"
    ></lib-metric-structure-group-form>
    <lib-metric-structure-table
      *ngIf="valueDefinitionGroupOrTable.table_id"
      [canMoveDown]="canMoveDown || i < groupsOrTables.length - 1"
      [canMoveUp]="canMoveUp || i > 0"
      [index]="i"
      [metricTableGroup]="$any(valueDefinitionGroupOrTable)"
      [metric]="metric"
      [isActive]="selectedItem?.id === valueDefinitionGroupOrTable.table_id"
      [indexOffSet]="coreGroupOrTableLength"
      class="form-group-wrapper"
    ></lib-metric-structure-table>
  </ng-container>
</ng-template>

<ng-template #emptyCustomizableSectionWarning>
  <div class="info-message text-center">{{ "No groups, fields, or tables have been added." | translate }}</div>
</ng-template>
