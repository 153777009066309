import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { TrimMethod } from './trim-whitespace.model';

@Directive({ selector: 'input[libTrimWhitespace], textarea[libTrimWhitespace]' })
export class TrimWhitespaceDirective {
  private trimFunctions: { [key in TrimMethod]: (val: string) => string } = {
    [TrimMethod.none]: (value) => value,
    [TrimMethod.start]: (value) => value.trimStart(),
    [TrimMethod.end]: (value) => value.trimEnd(),
    [TrimMethod.both]: (value) => value.trim(),
    [TrimMethod.full]: (value) => value.replace(/\s+/g, ' ').trim(),
  };
  @Input() libTrimWhitespace: TrimMethod = TrimMethod.none;

  constructor(private elementRef: ElementRef) {}

  trimText(value: string): string {
    return this.trimFunctions[this.libTrimWhitespace](value);
  }

  @HostListener('blur', ['$event.target', '$event.target.value'])
  onBlur(el: HTMLInputElement | HTMLTextAreaElement, value: string): void {
    const trimmedValue = this.trimText(value);
    if (trimmedValue !== value) {
      el.value = trimmedValue;
      el.dispatchEvent(new Event('input'));
      el.dispatchEvent(new Event('blur'));
    }
  }
}
