<div
  class="form-group-header"
  [ngClass]="{
    'color-error': !valueDefinitionGroup.value_definitions?.length || isElementTriggered(valueDefinitionGroup),
  }"
>
  <div class="fx-row">
    <mat-icon
      *ngIf="isElementTriggered(valueDefinitionGroup)"
      class="icon-md mr-0"
      svgIcon="condition-trigger"
    ></mat-icon>
    <mat-icon class="icon-md" svgIcon="group"></mat-icon>
  </div>

  <span class="mr-1 status-chip" *ngIf="valueDefinitionGroup | isDeactivated">{{ "Inactive" | translate }}</span>
  <span class="mat-small" [ngClass]="{ inactive: valueDefinitionGroup | isDeactivated }">
    {{ valueDefinitionGroup.label || "Group {iteration}" | translate: { iteration: index + 1 } }}
  </span>
</div>

<div class="form-group-empty-content" *ngIf="!valueDefinitionGroup.value_definitions?.length"></div>

<div class="form-group-content form-group">
  <ng-container *ngIf="isCoreValueDefinitionGroupOfReferenceV2Metric; else customizableFields">
    <lib-metric-structure-field-form
      *ngFor="let valueDefinition of valueDefinitionGroup.value_definitions; let i = index"
      [class]="'formfield ' + getValueDefinitionFormat(valueDefinition).size"
      [ngClass]="{
        newline: valueDefinition.newline,
        required: valueDefinition.required,
        readonly: getValueDefinitionFormat(valueDefinition).readonly,
      }"
      [valueDefinition]="valueDefinition"
      [valueDefinitionGroup]="valueDefinitionGroup"
      [vdIndex]="i"
      [isActive]="selectedItemId === valueDefinition.id"
      [attr.data-testid]="'vd-' + valueDefinition.id"
    ></lib-metric-structure-field-form>
  </ng-container>

  <ng-template #customizableFields>
    <lib-metric-structure-field-form
      *ngFor="let valueDefinition of valueDefinitionGroup.value_definitions; let i = index"
      cdkDrag
      [cdkDragData]="valueDefinition"
      [cdkDragDisabled]="valueDefinition | isValueDefinitionDragDisabled: metric : isAdmin"
      [class]="'formfield ' + getValueDefinitionFormat(valueDefinition).size"
      [ngClass]="{
        newline: valueDefinition.newline,
        required: valueDefinition.required,
        readonly: getValueDefinitionFormat(valueDefinition).readonly,
      }"
      [valueDefinition]="valueDefinition"
      [valueDefinitionGroup]="valueDefinitionGroup"
      [vdIndex]="i"
      [isActive]="selectedItemId === valueDefinition.id"
      [attr.data-testid]="'vd-' + valueDefinition.id"
    ></lib-metric-structure-field-form>
  </ng-template>
</div>
<div
  class="overlay"
  [ngClass]="{
    active: selectedItemId === valueDefinitionGroup.id,
  }"
  (click)="setSelectedItem(valueDefinitionGroup)"
  [attr.data-testid]="'overlay-' + valueDefinitionGroup.id"
>
  <div class="actions">
    <ng-container *ngIf="!isCoreValueDefinitionGroupOfReferenceV2Metric">
      <button
        class="btn btn-sm"
        *ngIf="canMoveDown"
        (click)="moveGroupDown($event)"
        [attr.aria-label]="'move down-' + valueDefinitionGroup.id"
      >
        {{ "Move Down" | translate }}
      </button>
      <button
        class="btn btn-sm"
        *ngIf="canMoveUp"
        (click)="moveGroupUp($event)"
        [attr.aria-label]="'move up-' + valueDefinitionGroup.id"
      >
        {{ "Move Up" | translate }}
      </button>
    </ng-container>
    <button
      class="btn btn-sm"
      *ngIf="
        (!this.valueDefinitionGroup.core_value_definition_group_id || isAdmin) &&
        !this.valueDefinitionGroup.value_definitions?.length
      "
      (click)="deleteGroup($event)"
      aria-label="groupDelete"
    >
      {{ "Delete" | translate }}
    </button>
  </div>
</div>
