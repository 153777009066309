import { Component, Input, OnChanges } from '@angular/core';
import { ProgressBarV2ClassColours } from '../../../models/progress-bar-v2';

@Component({
  selector: 'lib-progress-bar-v2',
  templateUrl: './progress-bar-v2.component.html',
  styleUrls: ['./progress-bar-v2.component.scss'],
})
export class ProgressBarV2Component implements OnChanges {
  shouldShowValue: boolean = false;

  @Input() showValue: boolean = false;
  @Input() progress: number = 0;
  @Input() sourceClass: ProgressBarV2ClassColours = ProgressBarV2ClassColours.GREY_PROGRESS;

  ngOnChanges() {
    this.shouldShowValue = this.showValue && this.progress > 0;
  }
}
