import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ActionItem,
  FiscalYear,
  Frequency,
  Metric,
  ValueDefinition,
  YearToDateCalculation,
  ApiResponse,
  ValueDefinitionFrequency,
  BulkUpdateValueDefinitionFrequencyRequest,
} from '../../../../models';
import { RadioButtonOption } from '../../../../components';
import { TranslateService } from '../../../../services/common';
import { MetricStructureFieldFrequencyForm } from './metric-structure-field-frequency.form';
import { MetricApiService } from '../../../../services/types';
import { map, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lib-metric-structure-field-frequency',
  templateUrl: './metric-structure-field-frequency.component.html',
  styleUrls: ['./metric-structure-field-frequency.component.scss'],
})
export class MetricStructureFieldFrequencyComponent implements OnInit, AfterViewInit {
  @Input({ required: true }) metric!: Metric;
  @Input({ required: true }) valueDefinition!: ValueDefinition;
  @Input() panelTabAction?: ActionItem;
  @Input() fiscalYears: FiscalYear[] = [];

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();

  public readonly yearToDateCalculationOptions: RadioButtonOption[] = [
    { label: this.translateService.instant('YTD Sum'), value: YearToDateCalculation.SUM },
    { label: this.translateService.instant('YTD Average'), value: YearToDateCalculation.AVERAGE },
    { label: this.translateService.instant('YTD Latest value'), value: YearToDateCalculation.LATEST },
  ];
  public readonly frequencyItemOptions: ActionItem<Frequency>[] = [
    { item: Frequency.YEARLY, id: Frequency.YEARLY, title: this.translateService.instant('Yearly') },
    { item: Frequency.QUARTERLY, id: Frequency.QUARTERLY, title: this.translateService.instant('Quarterly') },
    { item: Frequency.MONTHLY, id: Frequency.MONTHLY, title: this.translateService.instant('Monthly') },
  ];

  public loading: boolean = false;
  public metricStructureFieldFrequencyForm?: MetricStructureFieldFrequencyForm;

  constructor(
    private readonly translateService: TranslateService,
    readonly destroyRef: DestroyRef,
    private readonly metricsApiService: MetricApiService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.metricsApiService
      .listValueDefinitionFrequencies(
        this.metric.id,
        this.valueDefinition.value_definition_group_id,
        this.valueDefinition.id,
      )
      .pipe(
        map((response: ApiResponse<ValueDefinitionFrequency[]>) => response.data),
        map((valueDefinitionFrequencies: ValueDefinitionFrequency[]) =>
          valueDefinitionFrequencies.filter((vdf) => this.fiscalYears.some((fy) => fy.id === vdf.fiscal_year_id)),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((valueDefinitionFrequencies: ValueDefinitionFrequency[]) => {
        this.metricStructureFieldFrequencyForm = new MetricStructureFieldFrequencyForm(
          valueDefinitionFrequencies,
          this.fiscalYears,
          this.destroyRef,
        );
      });
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
  public submitForm(): void {
    if (!this.metricStructureFieldFrequencyForm || this.metricStructureFieldFrequencyForm.invalid) {
      return;
    }

    this.metricStructureFieldFrequencyForm.toModel();

    const bulkUpdatePayload: BulkUpdateValueDefinitionFrequencyRequest = {
      value_definition_frequencies: this.metricStructureFieldFrequencyForm.toModel(),
    };

    this.metricsApiService
      .bulkUpdateValueDefinitionFrequencies(
        this.metric.id,
        this.valueDefinition.value_definition_group_id,
        this.valueDefinition.id,
        bulkUpdatePayload,
      )
      .pipe(
        tap(() => {
          this.loading = true;
        }),
      )
      .subscribe(() => {
        this.loading = false;
        this.metricStructureFieldFrequencyForm?.markAsPristine();
        this.metricStructureFieldFrequencyForm?.disableYearToDateCalculationControlIfSet();
      });
  }

  public cancel(): void {
    this.closePanel.emit();
  }
}
