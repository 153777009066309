import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorState } from 'primeng/paginator/paginator.interface';
import { TablePageEvent } from '../../models';

interface PaginationTranslationParams {
  first: number;
  last: number;
  total: number | string;
}

@Component({
  selector: 'lib-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss'],
})
export class TablePaginatorComponent {
  public readonly PAGINATION_ROWS_PER_PAGE: number[] = [5, 10, 25, 50];
  @Input() withPaginationAlternatives: boolean = true;
  @Input() showCurrentPageReportTemplateText: boolean = true;

  public _totalItemCount?: number;
  @Input() set totalItemCount(totalItemCount: number | undefined) {
    this._totalItemCount = totalItemCount;
    this.updatePaginationTranslationParams();
  }

  public _pageSize: number = this.PAGINATION_ROWS_PER_PAGE[2];
  @Input() set pageSize(pageSize: number | undefined) {
    if (pageSize) {
      this._pageSize = pageSize;
    }
  }

  @Output() pageChanged: EventEmitter<TablePageEvent> = new EventEmitter<TablePageEvent>();

  public firstPageItemRelativeIndex: number = 0;
  public paginationTranslationParams?: PaginationTranslationParams;

  public changePage(event: PaginatorState): void {
    this.firstPageItemRelativeIndex = Number(event.first);
    this._pageSize = event.rows ?? this.PAGINATION_ROWS_PER_PAGE[2];
    this.pageChanged.emit({
      currentPage: Number(event.page) + 1,
      pageSize: this._pageSize,
    });
    this.updatePaginationTranslationParams();
  }

  private updatePaginationTranslationParams(): void {
    this.paginationTranslationParams = {
      first: this.firstPageItemRelativeIndex + 1,
      last: Math.min(this.firstPageItemRelativeIndex + this._pageSize, this._totalItemCount ?? 0),
      total: this._totalItemCount ?? '?',
    };
  }
}
