<th *ngIf="isRowReorderable" class="reorder-column-size"></th>

<th *ngIf="isExpandable" class="action-column-size"></th>

<ng-container *ngFor="let column of columns; last as last">
  <th
    *ngIf="!column.isSortable; else sortableColumn"
    pResizableColumn
    [ngStyle]="{
      width: column.width ?? 'unset',
      'max-width': column.width ?? 'unset',
      'min-width': column.minWidth ?? 'unset',
    }"
    class="wrapping-value"
  >
    <ng-container
      *ngTemplateOutlet="column.headerTemplate || defaultHeader; context: { $implicit: column.name }"
    ></ng-container>

    <ng-template #defaultHeader>{{ column.noHeader ? "" : column.name }}</ng-template>

    <div
      *ngIf="
        horizontalPaginationConfig && horizontalPaginationConfig.pageSize < horizontalPaginationConfig.total && last
      "
      class="horizontal-pagination"
      [ngClass]="{ 'horizontal-pagination-loading': horizontalPaginationConfig.isLoading }"
    >
      <ng-container *ngIf="!horizontalPaginationConfig.isLoading; else horizontalLoader">
        <p-button
          icon="pi pi-arrow-left"
          data-testid="prev-horizontal-page"
          [rounded]="true"
          [text]="true"
          severity="secondary"
          [disabled]="horizontalPaginationConfig.currentIndex <= 0"
          (click)="horizontalPageChange(-1)"
        ></p-button>
        <p-button
          icon="pi pi-arrow-right"
          data-testid="next-horizontal-page"
          [rounded]="true"
          [text]="true"
          [disabled]="
            horizontalPaginationConfig.currentIndex + horizontalPaginationConfig.pageSize >=
            horizontalPaginationConfig.total
          "
          severity="secondary"
          (click)="horizontalPageChange(1)"
        >
        </p-button>
      </ng-container>

      <ng-template #horizontalLoader>
        <lib-spinner data-testid="loading-horizontal-page" [diameter]="15"></lib-spinner>
      </ng-template>
    </div>
  </th>

  <ng-template #sortableColumn>
    <th
      pResizableColumn
      [pSortableColumn]="$any(column.dataKey)"
      [ngStyle]="{ width: column.width ?? 'unset', 'max-width': column.width ?? 'unset' }"
    >
      {{ column.name }}
      <p-sortIcon [field]="$any(column.dataKey)"></p-sortIcon>
    </th>
  </ng-template>
</ng-container>

<th *ngIf="actionMenuItems?.length" class="action-column-size"></th>
