/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import { Component, DoCheck, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { DateAdapter, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';

import { CustomDateAdapter } from './date-utils/custom-date-adapter';
import { ValidationMessageService } from '../../../services/common';
import { DateFormats } from '../../../models';
import { ValueDefinitionSize } from '../../../models';
import { DateUtils } from '../../../classes';

let nextId = 0;

@Component({
  selector: 'lib-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],
})
export class DateInputComponent implements OnInit, DoCheck, OnChanges {
  @Input() label = '';
  @Input() control?: UntypedFormControl;
  @Input() placeholder: string = '';
  @Input() hint: string = '';
  @Input() messages: ValidationErrors = {};
  @Input() format: DateFormats = DateFormats.yyyymmdd;
  @Input() size: ValueDefinitionSize = ValueDefinitionSize.large;
  @Input() readonly = false;
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() labelPosition: 'top' | 'left' = 'top';

  @ViewChild('focusElement') focusElement!: ElementRef;
  readonly _inputId = `date-input-${nextId++}`;

  tempControl: UntypedFormControl = new UntypedFormControl('', { updateOn: 'blur' });
  oldValue: string = '';
  oldErrors?: ValidationErrors | null;
  required: boolean = false;
  errorMessages: ValidationErrors = {};
  oldTouchedStatus = false;

  constructor(private validationMessageService: ValidationMessageService) {}

  ngOnInit(): void {
    this.setMatNativeDateFormat();
    this.initTemporaryControl();

    this.tempControl.valueChanges.subscribe((value) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.handleActualControlUpdation(value);
    });

    this.initializeTempControlDisableStatus();
    this.handleDisableStatusChanges();
  }

  ngDoCheck() {
    this.handleValueChange();
    this.handleTouchedStatusChange();
  }

  ngOnChanges(): void {
    this.initializeInput();
  }

  setFocus(): void {
    this.focusElement.nativeElement.focus();
  }

  setBlur(): void {
    this.focusElement.nativeElement.blur();
  }

  private initializeInput() {
    this.required = this.control?.hasValidator(Validators.required) ?? false;
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };
  }

  private handleValueChange(): void {
    if (this.oldValue !== this.control?.value) {
      this.oldValue = this.control?.value;
      this.tempControl.setValue(this.control?.value ? DateUtils.dateParse(this.control.value as string) : '', {
        emitEvent: false,
      });
    }

    if (this.oldErrors !== this.control?.errors) {
      this.tempControl.setErrors(this.control?.errors ?? null);
      this.oldErrors = this.control?.errors ?? null;
    }
  }

  private handleTouchedStatusChange(): void {
    if (this.control) {
      if (this.oldTouchedStatus !== this.control.touched) {
        if (this.control.touched) {
          this.tempControl.markAsTouched();
        } else {
          this.tempControl.markAsUntouched();
        }
        this.oldTouchedStatus = this.control.touched;
      } else if (this.tempControl.touched) {
        this.control.markAsTouched();
        this.oldTouchedStatus = true;
      }
    }
  }

  private handleActualControlUpdation(value?: Date): void {
    this.control?.setErrors(null);
    if (this.tempControl.valid) {
      this.control?.setValue(value ? DateUtils.dateFormat(value) : null);
      this.control?.markAsDirty();
    } else {
      this.control?.setErrors(this.tempControl.errors);
      if (this.tempControl.errors?.matDatepickerParse && this.tempControl.errors.required) {
        // When the control is invalid due to parse error, the control value automatically gets set to null.
        // Hence remove the required error from display.
        delete this.tempControl.errors.required;
      }
    }
  }

  private initTemporaryControl(): void {
    if (this.control?.validator) {
      this.tempControl.setValidators(this.control.validator);
    }
  }

  private setMatNativeDateFormat(): void {
    MAT_NATIVE_DATE_FORMATS.display.dateInput = this.format;
    MAT_NATIVE_DATE_FORMATS.parse.dateInput = this.format;
  }

  public clearSelectedDate(): void {
    this.tempControl.reset();
  }

  private handleDisableStatusChanges(): void {
    this.control?.registerOnDisabledChange((isDisabled) => {
      this.updateTempControlDisableStatus(isDisabled);
    });
  }

  private initializeTempControlDisableStatus(): void {
    this.updateTempControlDisableStatus(this.control?.status === 'DISABLED');
  }

  private updateTempControlDisableStatus(isDisabled: boolean): void {
    if (isDisabled) {
      this.tempControl.disable({ emitEvent: false });
    } else {
      this.tempControl.enable({ emitEvent: false });
    }
  }
}
