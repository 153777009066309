import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { DEFAULT_DONUT_CHART_CONFIG } from '../models';

@Component({
  selector: 'lib-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrl: './donut-chart.component.scss',
})
export class DonutChartComponent implements OnChanges {
  @Input() customOptions: Highcharts.Options = DEFAULT_DONUT_CHART_CONFIG;
  @Input() enableCustomLegend: boolean = true;

  customOptionsCopy = DEFAULT_DONUT_CHART_CONFIG;

  readonly Highcharts: typeof Highcharts = Highcharts;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customOptions) {
      // Adding timeout to avoid flickering caused because the chart loads super fast
      //  when no data and readjusts size after the legend is updated.
      setTimeout(() => {
        this.customOptionsCopy = { ...this.customOptions };
      }, 0);
    }
  }
}
