import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  constructor(private snackBar: MatSnackBar) {}

  success(message: string): void {
    this.open(message, '', 'success');
  }

  warning(message: string): void {
    this.open(message, '', 'warning');
  }

  error(message: string): void {
    this.open(message, '', 'error');
  }

  open(
    message: string,
    action: string,
    status: string,
    duration?: number,
    horizontalPosition?: string,
    verticalPosition?: string,
    truncate: boolean = false,
  ): MatSnackBarRef<any> | null {
    let displayMessage: string = message;
    if (truncate) {
      displayMessage = message.slice(0, 80);
      displayMessage = displayMessage === message ? message : `${displayMessage}...`;
    }

    if (!horizontalPosition) {
      horizontalPosition = 'center';
    } else if (['left', 'center', 'right'].indexOf(horizontalPosition) < 0) {
      throw new Error(`Horizontal position not supported: ${horizontalPosition}`);
    }
    if (!verticalPosition) {
      verticalPosition = 'top';
    } else if (['top', 'bottom'].indexOf(verticalPosition) < 0) {
      throw new Error(`Vertical position not supported: ${verticalPosition}`);
    }
    if (!duration) {
      duration = 2000;
    }
    const cssClassList = ['alert'];
    switch (status) {
      case 'warning': {
        cssClassList.push('bkg-warn');
        break;
      }
      case 'error': {
        cssClassList.push('bkg-error');
        break;
      }
      case 'success': {
        cssClassList.push('bkg-success');
        break;
      }
      default: {
        cssClassList.push('bkg-grey-50', 'default-style');
        break;
      }
    }
    action ? '' : cssClassList.push('no-action');
    switch (horizontalPosition) {
      case 'left':
        if (verticalPosition === 'top') {
          this.snackBar.open(displayMessage, action, {
            duration,
            panelClass: cssClassList,
            horizontalPosition: 'left',
            verticalPosition: 'top',
          });
        } else if (verticalPosition === 'bottom') {
          this.snackBar.open(displayMessage, action, {
            duration,
            panelClass: cssClassList,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        }
        break;
      case 'center':
        if (verticalPosition === 'top') {
          this.snackBar.open(displayMessage, action, {
            duration,
            panelClass: cssClassList,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        } else if (verticalPosition === 'bottom') {
          this.snackBar.open(displayMessage, action, {
            duration,
            panelClass: cssClassList,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
        }
        break;
      case 'right':
        if (verticalPosition === 'top') {
          this.snackBar.open(displayMessage, action, {
            duration,
            panelClass: cssClassList,
            horizontalPosition: 'right',
            verticalPosition: 'top',
          });
        } else if (verticalPosition === 'bottom') {
          this.snackBar.open(displayMessage, action, {
            duration,
            panelClass: cssClassList,
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
          });
        }
        break;
      default:
        break;
    }
    return this.snackBar._openedSnackBarRef;
  }
}
