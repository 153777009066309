import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../../services/common';

@Pipe({
  name: 'enumTranslate',
})
export class EnumTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(enumObject: Record<string, string>, translatedObject: Record<string, string>, value?: string): string {
    if (!value) {
      return '';
    }

    const key = Object.keys(enumObject).find((key: string) => enumObject[key] === value);
    if (!key) {
      return '';
    }

    const translatedObjKey: string = translatedObject[key];
    if (!translatedObjKey) {
      return '';
    }

    return this.translateService.instant(translatedObjKey);
  }
}
