import { Injectable } from '@angular/core';
import {
  BaseValue,
  BooleanTypeDetails,
  BooleanValue,
  ChoiceTypeDetails,
  ChoiceValue,
  CONDITIONAL_TRIGGER_SOURCE_TYPES,
  ConditionalTrigger,
  isValue,
  NumberTypeDetails,
  Value,
  ValueDefinitionType,
  ValueGroupSet,
  ValueGroupSetsTemplate,
  ValueTemplate,
} from '../../../models';
import { ConditionalTriggerUtils } from '../../../classes/ConditionalTriggerUtils/conditional-trigger-utils';

@Injectable({
  providedIn: 'root',
})
export class ConditionalTriggerService {
  isSupportedType(type: ValueDefinitionType): boolean {
    return CONDITIONAL_TRIGGER_SOURCE_TYPES.includes(type);
  }

  hasTriggerTriggeredForValueGroupSetTemplate(
    triggers: ConditionalTrigger[],
    valueGroupSet: ValueGroupSetsTemplate<BaseValue>,
  ): boolean {
    const allValues = valueGroupSet.group
      .reduce((values: ValueTemplate<BaseValue>[], group) => [...values, ...group.value], [])
      .map((valueTemplate) => this.valueTemplateToBaseValue(valueTemplate));
    return this.hasTriggerTriggered(triggers, allValues);
  }

  hasTriggerTriggeredForValueGroupSet(triggers: ConditionalTrigger[], valueGroupSet: ValueGroupSet): boolean {
    const allValues =
      valueGroupSet.value_groups?.reduce((values: Value[], group) => [...values, ...(group.values ?? [])], []) ?? [];

    return this.hasTriggerTriggered(triggers, allValues);
  }

  private hasTriggerTriggered(triggers: ConditionalTrigger[], values: BaseValue[]): boolean {
    return triggers.some((trigger: ConditionalTrigger) =>
      this.isTriggerTriggered(
        trigger,
        values.find(
          (value: BaseValue) =>
            value.value_definition_id === trigger.source_value_definition_id && !value.year_to_date_value_id,
        ),
      ),
    );
  }

  getUntriggeredFilledValues<T extends BaseValue>(
    updatedValueTemplate: ValueTemplate<T>,
    valueGroupSet: ValueGroupSetsTemplate<T>,
  ): ValueTemplate<T>[] {
    const updatedValue = this.valueTemplateToBaseValue(updatedValueTemplate);
    const untriggeredGroups = valueGroupSet.group.filter((group) =>
      this.hasUntriggeredTriggerForValue(group.conditional_triggers ?? [], updatedValue),
    );
    const filledValuesFromUntriggeredGroups = untriggeredGroups
      .reduce((values: ValueTemplate<T>[], group) => [...values, ...group.value], [])
      .filter((v) => v.value != null && v.value != '');

    const untriggeredFilledValues =
      valueGroupSet.group
        .find((group) => group.id === this.getValueGroupId(updatedValue))
        ?.value.filter((v) => this.hasUntriggeredTriggerForValue(v.conditional_triggers ?? [], updatedValue))
        .filter((v) => v.value != null && v.value != '') ?? [];

    return [...filledValuesFromUntriggeredGroups, ...untriggeredFilledValues];
  }

  private isTriggerTriggered(
    trigger: ConditionalTrigger,
    value?: BaseValue<any, BooleanValue | ChoiceValue | string>,
  ): boolean {
    if (value) {
      switch (value.type) {
        case ValueDefinitionType.boolean:
          const booleanValue: boolean = (value as BaseValue<BooleanTypeDetails, BooleanValue>).value?.value as boolean;
          return (trigger.values as boolean[]).includes(booleanValue);

        case ValueDefinitionType.number:
          return ConditionalTriggerUtils.compareValues(
            (value as BaseValue<NumberTypeDetails, string>).value,
            String(trigger.values),
            trigger.operator,
          );

        case ValueDefinitionType.choice:
        default:
          const choiceValues: string[] | undefined = (value as BaseValue<ChoiceTypeDetails, ChoiceValue>).value?.values;
          return (
            Array.isArray(choiceValues) && (trigger.values as string[]).every((value) => choiceValues.includes(value))
          );
      }
    }
    return false;
  }

  private hasUntriggeredTriggerForValue(triggers: ConditionalTrigger[], value: BaseValue): boolean {
    return triggers
      .filter((t) => t.source_value_definition_id === value.value_definition_id)
      .some((t) => !this.isTriggerTriggered(t, value));
  }

  private valueTemplateToBaseValue(valueTemplate: ValueTemplate<BaseValue>): BaseValue {
    return { ...valueTemplate.dataFormats.data, value: valueTemplate.value };
  }

  private getValueGroupId(value: BaseValue): string | undefined {
    if (isValue(value)) {
      return value.value_group_id;
    }

    return undefined;
  }
}
