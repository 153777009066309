<div
  *ngIf="control"
  libFormField
  [size]="size"
  [required]="required"
  [control]="control"
  [readonly]="readonly"
  [labelPosition]="labelPosition"
  [dataTestId]="'multiSelectChipInputFormField'"
>
  <lib-form-field-label [label]="label" [control]="control" [for]="_inputId"></lib-form-field-label>
  <mat-form-field>
    <mat-chip-grid #chipList aria-label="chipList">
      <mat-chip-row
        *ngFor="let item of selectedOptions"
        [ngClass]="{ 'existing-option': options | includes: item }"
        [removable]="!readonly"
        [disabled]="control.disabled"
        (removed)="removeValue(getOptionValue(item))"
      >
        <div class="choice-content" [title]="getOptionLabel(item)">{{ getOptionLabel(item) }}</div>
        <mat-icon *ngIf="!readonly" matChipRemove svgIcon="close" data-testid="chipRemoveIcon"></mat-icon>
      </mat-chip-row>
      <input
        [readonly]="readonly"
        #input
        [id]="_inputId"
        [formControl]="inputControl"
        [matAutocomplete]="autocomplete"
        [matChipInputAddOnBlur]="false"
        [matChipInputFor]="chipList"
        (matChipInputTokenEnd)="_addValue($event)"
        libFocusState
        [focusStateControl]="control"
      />
    </mat-chip-grid>
    <mat-autocomplete #autocomplete="matAutocomplete" (optionSelected)="selectOption($event)" aria-label="autocomplete">
      <mat-option
        *ngFor="let item of filteredOptions$ | async"
        [value]="getOptionValue(item)"
        [attr.aria-label]="getOptionLabel(item)"
      >
        {{ getOptionLabel(item) }}
      </mat-option>
    </mat-autocomplete>
    <mat-hint>
      <ng-container *ngIf="(control.untouched || control.valid) && hint && inputControl.valid">
        {{ hint }}
      </ng-container>
      <ng-container *ngIf="inputControl.invalid">
        <span class="color-error" *ngFor="let error of inputControl.errors | errorKeys">
          {{ errorMessages[error] }}</span
        >
      </ng-container>
      <ng-container *ngIf="control.touched && control.invalid">
        <span class="color-error" *ngFor="let error of control.errors | errorKeys"> {{ errorMessages[error] }}</span>
      </ng-container>
    </mat-hint>
  </mat-form-field>
</div>
