<td *ngIf="isRowReorderable" class="reorder-column-size">
  <span class="pi pi-bars" pReorderableRowHandle></span>
</td>

<td *ngIf="isExpandable" class="action-column-size">
  <button
    type="button"
    pButton
    pRipple
    [pRowToggler]="rowData"
    class="p-button-text p-button-rounded p-button-plain"
    [icon]="isExpanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
  ></button>
</td>

<ng-container *ngFor="let column of columns">
  <td
    *ngIf="!(rowGroupingProperties ?? [] | includes: column.dataKey) || rowgroup"
    [attr.rowspan]="(rowGroupingProperties ?? [] | includes: column.dataKey) && rowgroup ? rowspan : 1"
    [ngStyle]="{
      width: column.width ?? 'unset',
      'max-width': column.width ?? 'unset',
      'min-width': column.minWidth ?? 'unset',
    }"
    [ngClass]="{ 'wrapping-value': isWrapping }"
  >
    <div class="outer-cell-content">
      <div class="inner-cell-content" [ngClass]="{ 'ellipsis-value': !isWrapping }" libTitleOnEllipsis>
        <ng-template #defaultCell>
          {{ (rowData | get: column.dataKey) ?? defaultEmptyValue }}
        </ng-template>

        <ng-container
          *ngTemplateOutlet="
            column?.cellTemplate || defaultCell;
            context: { $implicit: rowData | get: column.dataKey, row: rowData }
          "
        ></ng-container>
      </div>
    </div>
  </td>
</ng-container>

<td *ngIf="actionMenuItems?.length" class="action-column-size">
  <lib-table-body-row-action-cell [rowData]="rowData" [actionMenuItems]="actionMenuItems">
  </lib-table-body-row-action-cell>
</td>
