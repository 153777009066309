<div class="panel-properties-content-header mh-2 fx-row justify-content-between">
  <div class="panel-properties-content-header-title">
    <h4 class="mat-subtitle-2">{{ "Group" | translate }}</h4>
  </div>
  <div class="panel-properties-content-header-actions fx-row align-center">
    <ng-container *ngIf="metric?.category !== eMetricCategory.THIRD_PARTY">
      <button
        lib-button
        buttonType="icon"
        icon="trash"
        *ngIf="isGroupDeletionEnabled"
        (click)="deleteGroup($event)"
        data-testid="delete-group"
        class="ml-2"
      ></button>
    </ng-container>
  </div>
</div>

<div class="panel-properties-content-container fx-col">
  <form
    *ngIf="groupForm"
    class="form pb-1"
    [formGroup]="groupForm"
    [ngClass]="{ disabled: formDisabled }"
    data-testid="form"
  >
    <div class="formfield tip large" *ngIf="valueDefinitionGroup.end_fiscal_year_frequency_code">
      <lib-instructional-text>
        {{
          "Group deactivated from fiscal year {fiscalYear} inclusive"
            | translate: { fiscalYear: valueDefinitionGroup.end_fiscal_year_frequency_code }
        }}</lib-instructional-text
      >
    </div>

    <div class="formfield large">
      <!-- The empty hint is a hack because the maxCharacterCount is shown only when there is a hint -->
      <lib-text-input
        [label]="'Group label' | translate"
        hint=" "
        [control]="groupForm.labelControl"
        [maxCharacterCount]="groupLabelMaxCharLimit"
      ></lib-text-input>
    </div>

    <div class="formfield large pt-1">
      <lib-slide-toggle
        [control]="groupForm.toggleSubtitleControl"
        [label]="'Show the group label as a subtitle' | translate"
        data-testid="group-label-as-subtitle-toggle"
      >
      </lib-slide-toggle>
    </div>

    <div class="formfield medium">
      <lib-select-input
        [label]="'Indent group level' | translate"
        [control]="groupForm.indentControl"
        [options]="indentOptions"
        [hint]="'0 for no indent' | translate"
      >
      </lib-select-input>
    </div>

    <div class="formfield tip large">
      <div class="fx-row align-center">
        <mat-icon svgIcon="info" class="tip-icon color-grey-800 mr-2"></mat-icon>
      </div>
      <div class="text tip-text tip-text-dark mat-body color-grey-900 pl-3">
        {{ "Repeatable group are useful to collect multiple variations of the same form fields" | translate }}
      </div>
    </div>
    <ng-container *ngIf="displayRepeatableGroupConsolidationWarningMessage()">
      <div class="formfield tip large">
        <div class="fx-row align-center">
          <mat-icon svgIcon="warning" class="tip-icon color-grey-800 mr-2"></mat-icon>
        </div>
        <div class="text tip-text tip-text-dark mat-body color-grey-900 pl-3">
          {{ "Consolidation rules must be set to manual for all number fields in a repeatable group" | translate }}
        </div>
      </div>
    </ng-container>
    <div class="formfield large pt-1">
      <!--
        [disabled] is required because of a glitch that disabled state wont refresh on ngOnchange
        There Is a warning
      -->
      <lib-slide-toggle
        [matTooltip]="
          (valueDefinitionGroup.value_definitions | filter: filterCalculatedType).length
            ? ('Repeatable Groups cannot include calculated fields' | translate)
            : ''
        "
        data-testid="repeatable-toggle"
        [control]="groupForm.toggleRepeatableControl"
        [disabled]="groupForm.toggleRepeatableControl.disabled"
        [label]="'Make this a repeatable group' | translate"
      >
      </lib-slide-toggle>
    </div>

    <div
      class="formfield medium-large required ml-8"
      data-testid="group-limit"
      [ngClass]="{ disabled: groupForm.repeatLimitControl.disabled }"
    >
      <lib-text-input
        [label]="'Group repeat limit' | translate"
        [hint]="'0 for no limit' | translate"
        [control]="groupForm.repeatLimitControl"
        [messages]="repeatLimitErrorMsgs"
      ></lib-text-input>
    </div>
  </form>
  <div>
    <lib-divider class="pb-4"></lib-divider>

    <div class="form-submit">
      <a
        *ngIf="isNewGroup; else saveButton"
        lib-button
        buttonType="success"
        [label]="'Add' | translate"
        [disabled]="groupForm!.invalid"
        [isLoading]="!!(updating$ | async)"
        (click)="addGroupForm()"
      >
      </a>

      <ng-template #saveButton>
        <a
          lib-button
          buttonType="success"
          [label]="'Save' | translate"
          [disabled]="groupForm!.pristine || groupForm!.invalid"
          [isLoading]="!!(updating$ | async)"
          (click)="saveGroupForm()"
        >
        </a>
      </ng-template>

      <a lib-button buttonType="link" [label]="'Cancel' | translate" (click)="closeProperties()"> </a>
    </div>
  </div>
</div>
