export enum FeatureFlagType {
  BOOLEAN = 'boolean',
  STRING = 'string',
  NUMBER = 'number',
  INTEGER = 'integer',
  JSON = 'json',
}

export enum EvaluationReason {
  STATIC = 'static',
  DEFAULT = 'default',
  VALUE_ERROR = 'value_error',
}

type ValueType = boolean | string | number | any[] | Record<string, any>;

export type FeatureFlagKeys =
  | 'user_groups_enabled'
  | 'translation_enabled'
  | 'data_export_enabled'
  | 'file_attachment_v2_enabled'
  | 'framework_template'
  | 'enable_ref_metrics_v2'
  | 'metric_structure_deactivation_enabled'
  | 'benchmark_v1_enabled'
  | 'data_tables_enabled'
  | 'consolidation_background_tasks_enabled'
  | 'benchmark_v2_ref_dropdown_enabled'
  | 'mse_field_info_panel_enabled'
  | 'taxonomy_selection_enabled'
  | 'settings_option_lists_enabled'
  | 'bypass_consolidation_enabled'
  | 'frequency_enabled'
  | 'frequency_subperiods_calculations_enabled'
  | 'cdp_implementation_v2024'
  | 'metric_library_ai_search_enabled'
  | 'metric_structure_metric_table_deactivation_enabled'
  | 'new_request_workflow_enabled'
  | 'white_labeling_enabled'
  | 'metric_sync_enabled'
  | 'xbrl_export_enabled'
  | 'dccp_overview_enabled'
  | 'cobranding_enabled';

export interface EvaluatedFeatureFlag {
  key: FeatureFlagKeys;
  type: FeatureFlagType;
  value: ValueType;
  reason: EvaluationReason;
  metadata: Record<string, any>;
}
