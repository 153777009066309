import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileRemoveEvent, FileUpload, FileUploadHandlerEvent } from 'primeng/fileupload';
import { FileUploadMode } from '../../../models';

@Component({
  selector: 'lib-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss',
})
export class FileUploadComponent {
  @Input() mode: FileUploadMode = FileUploadMode.advanced;
  @Input() label: string = 'Upload File';
  @Input() accept: string = '*';
  @Input() url: string | undefined = undefined;
  @Input() method: 'post' | 'put' | undefined = undefined;
  @Input() multiple: boolean = false;
  @Input() disabled: boolean = false;
  @Input() autoUpload: boolean = false;
  @Input() maxFileSize: number = 5242880; // 5MB;
  @Input() maxFiles: number | undefined = undefined;
  @Input() showUploadButton: boolean = false;
  @Input() showCancelButton: boolean = false;
  @Input() chooseIcon: string | undefined = undefined;
  @Input() uploadButtonStyleClass: string | undefined = undefined;

  @Output() uploadHandler: EventEmitter<FileUploadHandlerEvent> = new EventEmitter<FileUploadHandlerEvent>();
  @Output() removeUploadFileHandler: EventEmitter<FileRemoveEvent> = new EventEmitter<FileRemoveEvent>();

  // A custom handler is defined as primeNg has a bug https://github.com/primefaces/primeng/issues/4018
  // that doesn't show up the file browser for re-selection once a file is uploaded so that we can emit
  // the uploader event as well as clear the files array.
  public fileUploadHandler(event: FileUploadHandlerEvent, fileUpload: FileUpload): void {
    this.uploadHandler.emit(event);

    // This enables showing up the file browser for reselection
    fileUpload.clear();
  }
}
