<p-fileUpload
  #fileUpload
  [mode]="mode"
  [url]="url"
  [chooseIcon]="chooseIcon"
  [method]="method"
  [accept]="accept"
  [chooseLabel]="label"
  [disabled]="disabled"
  [auto]="autoUpload"
  [maxFileSize]="maxFileSize"
  [showUploadButton]="showUploadButton"
  [showCancelButton]="showCancelButton"
  [fileLimit]="maxFiles"
  [multiple]="multiple"
  [customUpload]="true"
  [styleClass]="uploadButtonStyleClass"
  (uploadHandler)="fileUploadHandler($event, fileUpload)"
  (onRemove)="removeUploadFileHandler.emit($event)"
>
</p-fileUpload>
