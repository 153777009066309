import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ApplicationApiDefinition,
  DataRequest,
  DataRequestSourceSummary,
  SendReminderEmailToRequestParticipantsPayload,
} from '../../../models';
import { ApiService } from '../../common/api/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientDataRequestsV2Service {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.dataRequestsV2;
  }

  public activateDataRequest(dataRequestId: string): Observable<ApiResponse<DataRequest>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/data_requests/${dataRequestId}/activate`);
  }

  public activateDataRequestPrefill(dataRequestId: string): Observable<ApiResponse> {
    return this.apiService.post(`${this.servicePath}${this.resource}/data_requests/${dataRequestId}/activate/prefill`);
  }

  public sendReminderEmailToRequestParticipants(
    dataRequestId: string,
    dataRequestSourceId: string,
    payload: SendReminderEmailToRequestParticipantsPayload,
  ): Observable<ApiResponse> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/${dataRequestSourceId}/send_reminder_email`,
      payload,
    );
  }

  public listDataRequestSourcesSummary(dataRequestId: string): Observable<ApiResponse<DataRequestSourceSummary[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/data_requests/${dataRequestId}/data_request_sources/summary`,
    );
  }
}
