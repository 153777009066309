import { Injectable } from '@angular/core';
import { ACTION_ITEM_LEVEL, ActionItem, Source } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class SourceMenuItemConversionService {
  cssIndentationClass: { [key: number]: string } = {
    1: ACTION_ITEM_LEVEL.first_level_indent,
    2: ACTION_ITEM_LEVEL.second_level_indent,
    3: ACTION_ITEM_LEVEL.third_level_indent,
  };

  private squashActionItemTree(actionItemLevelTree: ActionItem<Source>[], level: number = 0): ActionItem<Source>[] {
    const sourceList: ActionItem<Source>[] = [];

    actionItemLevelTree.forEach((node: ActionItem<Source>) => {
      const childrenNodes = node.children === undefined ? [] : node?.children.slice();
      node.children = [];
      node.levelClass = this.cssIndentationClass[level];

      sourceList.push(node);

      if (childrenNodes.length > 0) {
        sourceList.push(...this.squashActionItemTree(childrenNodes, level + 1));
      }
    });

    return sourceList;
  }

  public convertActionItemsToFlattenHierarchyItems(sourceActionItems: ActionItem<Source>[]): ActionItem<Source>[] {
    // Creating Id mapping for reference to action items
    const idMapping: { [key: string]: ActionItem<Source> } = {};

    sourceActionItems.forEach((sourceActionItem: ActionItem<Source>) => {
      idMapping[sourceActionItem.id] = { ...sourceActionItem, children: [] };
    });

    const actionItemTree: ActionItem<Source>[] = [];
    // Building the tree
    sourceActionItems.forEach((obj) => {
      const parentBusinessUnitId: string = obj.item?.parent_business_unit_ids?.[0] ?? '';

      if (parentBusinessUnitId !== '' && idMapping[parentBusinessUnitId]) {
        const parent = idMapping[parentBusinessUnitId];
        parent.children?.push(idMapping[obj.id]);
      } else {
        actionItemTree.push(idMapping[obj.id]);
      }
    });

    // Sorting each level
    const sortChildren = (sourceActionItem: ActionItem) => {
      if (sourceActionItem.children) {
        sourceActionItem.children.sort((a, b) => a.title.localeCompare(b.title));
        sourceActionItem.children.forEach(sortChildren);
      }
    };

    actionItemTree.forEach(sortChildren);

    return this.squashActionItemTree(actionItemTree, 0);
  }
}
