import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { ValidationMessageService } from '../../../services/common';
import { interval, Subscription } from 'rxjs';
import { TrimMethod } from '../../../directives/trim-whitespace/trim-whitespace.model';

let nextId = 0;
const DEFAULT_ROW_NUMBER = 5;

@Component({
  selector: 'lib-text-area-input',
  templateUrl: './text-area-input.component.html',
  styleUrls: ['./text-area-input.component.scss'],
})
export class TextAreaInputComponent implements OnChanges, OnInit, OnDestroy {
  @Input() label = '';
  @Input() control?: UntypedFormControl;
  @Input() messages?: ValidationErrors;
  @Input() hint?: string;
  @Input() prefix?: string;
  @Input() placeholder = '';
  @Input() maxLength: number | null = null;
  @Input() rows: number = DEFAULT_ROW_NUMBER;
  @Input() cdkTextareaAutosize: boolean = true;
  @Input() cdkAutosizeMaxRows: number = 0;
  @Input() autoSaveInterval?: number;
  @Input() trimWhitespaceMethod: TrimMethod = TrimMethod.both;

  @Output() handleFocus: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleBlur: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('focusElement') focusElement!: ElementRef;

  readonly _inputId = `area-text-${nextId++}`;

  required: boolean = false;
  errorMessages: ValidationErrors = {};
  updatedControlValue: string | null = '';

  private autoSaveSubscription?: Subscription;

  constructor(private validationMessageService: ValidationMessageService) {}

  public ngOnInit() {
    this.control?.registerOnChange((updatedValue: string | null) => {
      this.updatedControlValue = updatedValue;
    });
  }

  public ngOnChanges(): void {
    this.initializeInput();
  }
  public ngOnDestroy(): void {
    this.autoSaveSubscription?.unsubscribe();
  }

  private initializeInput() {
    this.required = this.control?.hasValidator(Validators.required) ?? false;
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };
  }

  public setFocus(): void {
    this.focusElement.nativeElement.focus();
  }

  public setBlur(): void {
    this.focusElement.nativeElement.blur();
  }

  public updateValue(event: Event): void {
    if (event?.target instanceof HTMLTextAreaElement) {
      this.updatedControlValue = event?.target ? event?.target.value : '';
    }
  }

  public onFocus(): void {
    if (this.autoSaveInterval) {
      this.autoSaveSubscription = interval(this.autoSaveInterval).subscribe(() => {
        if (this.updatedControlValue !== this.control?.value) {
          this.control?.setValue(this.updatedControlValue);
        }
      });
    }

    this.handleFocus.emit();
  }

  public onBlur(): void {
    this.autoSaveSubscription?.unsubscribe();
    this.handleBlur.emit();
  }
}
