<div [ngClass]="type + '-banner'">
  <div class="icon-container">
    <mat-icon [attr.aria-label]="type + '-icon'" [svgIcon]="type" class="icon-lg"></mat-icon>
  </div>
  <div [ngClass]="type + '-separator'"></div>
  <div class="banner-text">
    <div>
      <ng-container *ngIf="!isHtmlText; else htmlText">
        {{ message }}
      </ng-container>
      <button *ngIf="cta" lib-button class="p-0" [buttonType]="'link'" (click)="callToAction.emit()">{{ cta }}</button>
    </div>
  </div>
</div>

<ng-template #htmlText>
  <div [innerHTML]="message"></div>
</ng-template>
