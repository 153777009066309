import { Injectable } from '@angular/core';
import { TranslateService } from '../translate/translate.service';
import { ActionItem, FiscalYear, FiscalYearStatus } from '../../../models';
import { FiscalYearsStatusTranslation } from '../../../translations/search/fiscal-years.translation';

@Injectable({
  providedIn: 'root',
})
export class FiscalYearsUtilService {
  private currentYear = new Date().getFullYear();

  constructor(private readonly translateService: TranslateService) {}

  public appendFiscalYearStatus(fiscalYearItem: ActionItem<FiscalYear>): ActionItem<FiscalYear> {
    return {
      ...fiscalYearItem,
      title:
        fiscalYearItem.item?.status === FiscalYearStatus.CLOSED ||
        fiscalYearItem.item?.status === FiscalYearStatus.DRAFT
          ? `${fiscalYearItem.title} (${this.translateService.instant(
              FiscalYearsStatusTranslation[fiscalYearItem.item.status],
            )})`
          : fiscalYearItem.title,
    };
  }

  public getDefaultFiscalYear(
    fiscalYears: ActionItem<FiscalYear>[],
    fiscalYearItem?: ActionItem<FiscalYear>,
    filterStatus?: FiscalYearStatus,
  ): ActionItem<FiscalYear> {
    if (filterStatus) {
      return FiscalYearsUtilService.getLatestFiscalYear(fiscalYears, filterStatus);
    }
    const defaultFiscalYear: ActionItem<FiscalYear> | undefined =
      fiscalYearItem ?? fiscalYears.find((year) => year.id === this.currentYear.toString());
    if (!defaultFiscalYear) {
      return FiscalYearsUtilService.getLatestFiscalYear(fiscalYears);
    }
    return defaultFiscalYear;
  }

  public static getLatestFiscalYear(
    fiscalYearsActionItems: ActionItem<FiscalYear>[],
    filterStatus?: FiscalYearStatus,
  ): ActionItem<FiscalYear> {
    if (filterStatus) {
      const fiscalYearsFilteredByStatus = [...fiscalYearsActionItems].filter((fy) => fy.item?.status == filterStatus);
      if (fiscalYearsFilteredByStatus.length) {
        return fiscalYearsFilteredByStatus.sort((y1, y2) => y2.item!.year - y1.item!.year)[0];
      }
    }
    return [...fiscalYearsActionItems].sort((y1, y2) => y2.item!.year - y1.item!.year)[0];
  }

  public static getPastYearsRangeOptions(years: ActionItem<FiscalYear>[]): ActionItem<number>[] {
    const ascSortedFiscalYearsActionItems = [...years].sort((y1, y2) => y2.item!.year - y1.item!.year);
    const startYear: number = +ascSortedFiscalYearsActionItems.slice(-1)[0].title;
    const endYear: number = +ascSortedFiscalYearsActionItems[0].title;

    if (startYear > endYear) {
      throw new Error('Start year cannot be greater than end year.');
    }

    const pastYearsRangeOptions: ActionItem<number>[] = [];

    for (let year = startYear; year <= endYear; year++) {
      const yearDiff = endYear - year + 1;

      pastYearsRangeOptions.push({
        title: yearDiff.toString(),
        id: yearDiff.toString(),
        item: yearDiff,
      });
    }

    return pastYearsRangeOptions;
  }

  public static getNthMostRecentFiscalYear(
    nth: number,
    fiscalYearActionItems: ActionItem<FiscalYear>[],
  ): ActionItem<FiscalYear> {
    return [...fiscalYearActionItems].sort((y1, y2) => y2.item!.year - y1.item!.year)[nth - 1];
  }

  public static fiscalYearsResponseToFiscalYears(fiscalYearsResponse: FiscalYear[]): FiscalYear[] {
    return fiscalYearsResponse.map((response) => FiscalYearsUtilService.fiscalYearToModel(response));
  }

  public static fiscalYearToModel(fiscalYearResponse: FiscalYear): FiscalYear {
    return {
      id: fiscalYearResponse.id,
      year: Number(fiscalYearResponse.frequency_code),
      start: fiscalYearResponse.start,
      end: fiscalYearResponse.end,
      status: fiscalYearResponse.status,
      framework_taxonomy_status: fiscalYearResponse.framework_taxonomy_status,
      frequency_code: fiscalYearResponse.frequency_code,
      name: fiscalYearResponse.name,
    };
  }
}
